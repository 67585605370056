import React from 'react';
import OriginalGithubButton from 'react-github-btn';
import styled from 'styled-components';
export const Container = styled.span`
  > span > span {
    display: inline-flex;
    vertical-align: middle;
  }
`;
export const GitHubButton = ({ className, ...props }) => (
  <Container className={className}>
    <OriginalGithubButton {...props} />
  </Container>
);
