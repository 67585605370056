import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';
const Container = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: ${({ small }) => (small ? '20px' : '30px')};

  ${({ white }) =>
    white &&
    css`
      filter: brightness(0) invert(1);
    `}

  ${({ grey }) =>
    grey &&
    css`
      filter: brightness(0);
      opacity: 0.4;
    `}
`;
const Image = styled.img`
  display: block;
  max-width: 100%;
  object-fit: contain;

  :not([height]) {
    height: 100%;
  }
`;
export const Logo = ({ data, white, grey, small, ...props }) => {
  if (!data) return null;
  const image = getImage(data);
  return (
    <Container small={small} white={white} grey={grey}>
      {!image ? (
        <Image loading="lazy" {...props} src={data.publicURL} />
      ) : (
        <GatsbyImage
          {...props}
          image={image}
          style={{
            width: '100%',
            height: '100%',
          }}
          imgStyle={{
            objectFit: 'contain',
            height: '100%',
          }}
        />
      )}
    </Container>
  );
};

export const query = graphql`
  fragment LogoImageData on File {
    publicURL
    childImageSharp {
      gatsbyImageData(transformOptions: { fit: CONTAIN }, height: 30)
    }
  }
  fragment LogoImageDataNoSharp on File {
    publicURL
  }
`;
