import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Player from 'react-player/lazy';
import { Badge } from 'src/components/atoms/Badge';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { HighlightedText } from 'src/components/atoms/HighlightedText';
import { Column, Grid } from 'src/components/molecules/Grid';
import { ShowcaseGrid } from 'src/components/molecules/ShowcaseGrid';
import { Teaser } from 'src/components/molecules/Teaser';
import { FixedImg } from 'src/embeds/image/image-dato';
import { FlowAlt } from 'src/system';
import styled from 'styled-components';
import { Link } from './Link';
import { SEO } from './SEO';
import { ShowcaseCategories } from './ShowcaseCategories';
const query = graphql`
  query ShowcaseOverviewData {
    featuredShowcase: datoCmsShowcase(featured: { eq: true }) {
      slug
      heading
      video {
        url: video {
          thumbnail: thumbnailUrl(format: jpg)
          streaming: streamingUrl
        }
        height
        width
      }
    }
    allDatoCmsShowcase(filter: { featured: { eq: false } }, sort: { order: ASC, fields: position }) {
      nodes {
        heading
        slug
        category {
          slug
          title
        }
        channels {
          title
        }
        community
        preHeading
        image {
          ...GatsbyDatoCmsImageCustom
        }
        linkedAuthor {
          ... on DatoCmsCustomer {
            logo {
              ...GatsbyDatoCmsImageCustom
            }
          }
        }
      }
    }
  }
`;
const Banner = styled(Card)`
  padding: 1.25rem 1.75rem;

  ${Badge} {
    margin-bottom: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 1rem;
    align-items: center;

    h3 {
      margin: 0;
    }

    ${Badge} {
      margin: 0;
    }
  }
`;
const FeaturedContent = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 1.25rem 1.75rem;
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
const FeaturedHeadline = styled.h2`
  margin-bottom: 1rem;
`;
const VideoColumn = styled(({ aspectRatio, ...props }) => <Column {...props} />)`
  position: relative;
  background-color: #2c3951;

  &:after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: ${({ aspectRatio }) => aspectRatio * 100}%;
  }
`;
export const ShowcaseOverview = () => {
  const data = useStaticQuery(query);
  const featuredShowcase = get(data, 'featuredShowcase');
  const showcases = get(data, 'allDatoCmsShowcase.nodes', []);
  return (
    <ShowcaseGrid sidebar={<ShowcaseCategories />}>
      <SEO title="Community Showcase" />
      <Banner tertiary>
        <Badge>NEW!</Badge>
        <h3>
          Introducing the showcase of Rasa-powered AI assistants - browse for inspiration or submit your own!
        </h3>
        <Button to="https://forms.gle/JK1Uc9RaXL34UnqH8">
          Submit your assistant
        </Button>
      </Banner>
      {!!featuredShowcase && (
        <Card small noPadding secondary>
          <Grid columns="1.5fr 2fr" verticalAlign="center" noGap>
            <Column>
              <FeaturedContent to={`/showcase/${featuredShowcase.slug}`}>
                <HighlightedText secondary margin>
                  Featured User
                </HighlightedText>
                <FeaturedHeadline>{featuredShowcase.heading}</FeaturedHeadline>
                Read more →
              </FeaturedContent>
            </Column>
            {!!featuredShowcase.video && (
              <VideoColumn aspectRatio={featuredShowcase.video.height / featuredShowcase.video.width}>
                <Player
                  controls
                  muted
                  loop
                  playing
                  width="100%"
                  height="100%"
                  url={featuredShowcase.video.url.streaming}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    maxWidth: '100%',
                  }}
                />
              </VideoColumn>
            )}
          </Grid>
        </Card>
      )}

      <Grid columns="1fr 1fr 1fr">
        {showcases.map((showcase) => {
          return (
            <Teaser
              to={`/showcase/${showcase.slug}`}
              header={
                !!showcase.image && ( // @ts-ignore
                  <FixedImg height={230} data={showcase.image} />
                )
              }
              subtitle={showcase.preHeading}
              title={showcase.heading}
              footer={
                showcase.community ? (
                  <HighlightedText bold>Rasa community project</HighlightedText>
                ) : (
                  showcase.linkedAuthor?.logo && (
                    <FlowAlt gap={8}>
                      {/* @ts-ignore */}
                      <FixedImg
                        constrain
                        width={Math.round(
                          (showcase.linkedAuthor.logo.width / showcase.linkedAuthor.logo.height) * 20,
                        )}
                        height={20}
                        data={showcase.linkedAuthor.logo}
                      />
                    </FlowAlt>
                  )
                )
              }
              key={showcase.slug}
            />
          );
        })}
      </Grid>
    </ShowcaseGrid>
  );
};
