import React from 'react';
import styled, { css } from 'styled-components';
const Container = styled.section`
  position: relative;
  padding: ${({ noTopPadding, noPadding, spacious, noBottomPadding }) => (noPadding ? '0 1rem' : spacious ? noBottomPadding ? '7rem 1rem 0' : '7rem 1rem;' : noBottomPadding ? '3.5rem 1rem 0' : noTopPadding ? '0 1rem 3.5rem' : '3.5rem 1rem;')};
  --section-background-color: ${({
    primary,
    secondary,
    dark,
    theme,
    white,
    customBackgroundColor
  }) => {
    if (customBackgroundColor) {
      return customBackgroundColor;
    }

    return primary
      ? theme.primaryColor
      : secondary
      ? '#f2f4fa'
      : dark
      ? '#2c3951'
      : white
      ? '#fff'
      : 'transparent'
    }
  };

  background-color: ${({ sloped }) => (sloped ? 'transparent' : 'var(--section-background-color)')};

  ${({ transparent }) => transparent &&
    css`
      background-color: transparent;
    `}

  ${({ primary, dark }) =>
    (dark || primary) &&
    css`
      color: white;
      --alternative-heading-color: white;
      --primary-color: white;
      --secondary-color: #343434;
      --card-color--secondary: #3e5473;
    `}

  ${({ customColor }) =>
    customColor &&
      css`
        color: ${customColor};
        --alternative-heading-color: ${customColor};
        --primary-color: ${customColor};
      `}

  ${({ secondary }) =>
    secondary &&
    css`
      color: #5a5c72;
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ bordered }) =>
    bordered &&
    css`
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `}

  ${({ overlap, sloped }) =>
    overlap &&
    (sloped
      ? css`
          padding-top: 16rem;
          margin-top: -10rem;
          z-index: 1;
        `
      : css`
          padding-top: 0;
          margin-top: -4rem;
          z-index: 1;
        `)}
`;
const BackgroundImageContainer = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  mix-blend-mode: multiply;
  pointer-events: none;
  opacity: 0.5;
`;
const SlopedBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: -1px;
  background-color: var(--section-background-color);
  z-index: -1;
  clip-path: polygon(0 16rem, 100% 2rem, 100% 100%, 0% 100%);
`;
const Content = styled.div`
  position: relative;
  padding:  ${({ cardBoxShadow }) => (cardBoxShadow ? '4rem' : 'unset')};
  border-radius:  ${({ cardBoxShadow }) => (cardBoxShadow ? '0.95rem' : 'unset')};
  box-shadow:  ${({ cardBoxShadow }) => (cardBoxShadow ? '0 9px 22px 0 rgba(0, 0, 0, 0.1)' : 'unset')};
  max-width: ${({ theme, maxWidth }) => {
    switch (maxWidth) {
      case 'small':
        return theme.smallContentWidth;

      case 'medium':
        return theme.mediumContentWidth;

      case 'large':
        return theme.largeContentWidth;

      case 'xlarge':
        return theme.xlargeContentWidth;

      case 'full':
        return '100%';

      default:
        return theme.largeContentWidth;
    }
  }};
  margin: 0 auto;
  z-index: 2;

  > *:last-child {
    margin-bottom: 0;
  }
`;
export const Section = ({
  children,
  primary,
  secondary,
  centered,
  bordered,
  spacious,
  sloped,
  overlap,
  maxWidth = 'medium',
  noPadding,
  noBottomPadding,
  noTopPadding,
  backgroundImage,
  transparent = false,
  white,
  cardBoxShadow,
  ...props
}) => {
  return (
    <Container
      primary={primary}
      secondary={secondary}
      white={white}
      centered={centered}
      spacious={spacious}
      sloped={sloped}
      overlap={overlap}
      noPadding={noPadding}
      noTopPadding={noTopPadding}
      noBottomPadding={noBottomPadding}
      bordered={bordered}
      transparent={transparent}
      {...props}
    >
      {!!backgroundImage && <BackgroundImageContainer>{backgroundImage}</BackgroundImageContainer>}
      {sloped && <SlopedBackground />}
      <Content cardBoxShadow={cardBoxShadow} maxWidth={maxWidth}>{children}</Content>
    </Container>
  );
};
export const SectionHeader = styled.header`
  max-width: 60rem;
  margin: 0 auto 3rem;
  ${({ centered }) => centered && 'text-align: center;'}

  h2 {
    @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
      ${({ nowrap }) =>
        nowrap &&
        css`
          white-space: nowrap;
        `}
    }
  }

  &:empty {
    display: none;
  }
`;
