import { css, createGlobalStyle } from 'styled-components';

const HeaderColorState = createGlobalStyle(({
  alternative,
  primary,
  dark,
  theme,
  visible,
  scrolled
}) => {
  return css`
    :root {
      ${({brandColor}) => brandColor && css`
      --brand-color: ${brandColor};
      `}
      --header-fg: ${({headerColor}) => {
        return (alternative || primary || headerColor) && visible ? 'white' : '#2c3951'
      }};
      --header-ui: ${({headerColor}) => {
        return (alternative || primary || headerColor) && visible ? 'white' : theme.primaryColor
      }};
      --header-bg: ${({headerColor}) => {
        if ((alternative || primary || dark || headerColor) && visible) {
          return dark
            ? '#2c3951'
            : headerColor
            ? headerColor
            : theme.primaryColor
        }

        return 'white';
      }};
      --header-sh: ${scrolled ? 'rgba(47, 17, 92, 0.13)' : 'transparent'};
      ${({ footerTextColor }) => footerTextColor && css`
        --footer-text-color: ${footerTextColor};
      `}
      ${({ footerBackgroundColor }) => footerBackgroundColor && css`
        --footer-background-color: ${footerBackgroundColor};
      `}
      ${({ footerHeaderColor }) => footerHeaderColor && css`
        --footer-header-color: ${footerHeaderColor};
      `}
    }
  `;
});

export default HeaderColorState;
