import { useEffect, useLayoutEffect } from 'react';
/*

see the following resources:

  react-use/useIsomorphicLayoutEffect.md at master · streamich/react-use
  https://github.com/streamich/react-use/blob/master/docs/useIsomorphicLayoutEffect.md

  useLayoutEffect and SSR. How to get warning free usage of… | by Alex Reardon | Medium
  https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a

  useEffect vs useLayoutEffect
  https://kentcdodds.com/blog/useeffect-vs-uselayouteffect

*/

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
