import { css } from 'styled-components';

export const dropMenu = css`
  position: relative;
  font-weight: 500;
  font-size: 16px;
`;

export const indentStyles = css`
  margin-left: 1rem;
`

export const dropMenuTrigger = css`
  /* --fg: #2c3951; */
  font: inherit;
  color: var(--fg);
  cursor: pointer;
  padding: 0;
  > .target {
    line-height: 1;
    display: inline-flex;
    align-items: center;
    height: 40px;
    padding: 0 1.25em;
    border-radius: 8px;
  }
  .site-nav-btn & > .target {
    position: relative;
    background: var(--fg);
    color: var(--bg);
    font-size: 15px;
  }
  &:focus {
    outline: none;
  }
  &.hover,
  &:focus-visible {
    text-decoration: none;

    .site-nav-btn & > .target {
      position: relative;
      background: var(--fg);
      color: var(--bg);
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        /* background: var(--brand-color);
        opacity: 0.05; */
      }
      background: var(--fg);
      color: var(--bg);
    }
    > .target {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

export const dropMenuContent = css`
  --ui: blue;
  --bg: white;
  --fg: #6d708b;
  --bd-width: 1px;
  --bd-offset: calc(var(--bd-width) * 1.4142);
  --bd: rgba(200, 200, 200, 0.5);
  --arrow-size: 10px;
  --column-gap: 50px;
  --parent-size: 100%;
  font-size: 15px;
  position: absolute;
  top: 100%;
  left: 50%;
  --translateX: -50%;
  transform: translate(var(--translateX, 0), var(--translateY, 0));
  &.align-right {
    --translateX: 0%;
    left: initial;
    right: var(--bd-width);
  }
  &.align-left {
    --translateX: 0%;
    left: var(--bd-width);
    right: unset;
  }
  &.xn-nav-dropdown-in {
    opacity: 1;
    --translateY: 0px;
    transition-timing-function: ease-out;
  }
  &.xn-nav-dropdown-out {
    opacity: 0;
    --translateY: 10px;
    transition-timing-function: ease-in;
  }
`;

// visible container, styled
export const dropMenuPanel = css`
  margin-top: 5px;
  padding: 1.25em calc(var(--column-gap) / 2) 1em;
  > ul > li {
    width: 12em; // comfortable width
  }
  position: relative;
  color: var(--fg);
  background: var(--bg);
  border: var(--bd-width) solid var(--bd);
  border-radius: 6px;
  --shadow-l: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--shadow-xl);
  .align-right > & {
    &::after,
    &::before {
      left: initial;
      right: calc(var(--arrow-size) * 4);
      transform: translateX(0);
    }
    &::before {
      transform: translateX(var(--bd-offset));
    }
  }
  .align-left > & {
    &::after,
    &::before {
      right: initial;
      left: calc(var(--arrow-size) * 4);
      transform: translateX(0);
    }
    &::before {
      transform: translateX(calc(var(--bd-offset) * -1));
    }
  }
  &::after,
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    pointer-events: none;
    transform: translateX(-50%);
  }
  &::after {
    border-bottom-color: var(--bg);
    border-width: calc(var(--arrow-size));
  }
  &::before {
    border-bottom-color: var(--bd);
    border-width: calc(var(--arrow-size) + var(--bd-offset));
  }
`;

export const dropMenuList = css`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: -1px;
  margin-right: -1px;
  color: var(--header-fg);
`;

export const dropMenuListItem = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const dropSubmenuLabel = css`
  padding: 0;
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6d708b;
`;

export const dropSubmenuList = css`
  margin-top: 8px;
  /* margin-left: -1px;
  margin-right: -1px; */
`;

export const dropMenuLink = css`
  /* color: var(--ui); */
  color: #2c3951;
  display: block;
  padding: 0.4em calc(var(--column-gap) / 2);
  margin: 0 calc(var(--column-gap) / -2);
  border-left: 3px solid var(--bd-color, transparent);
  &:focus {
    outline: 0;
  }
  &::focus-visible {
    outline: 2px blue;
  }
  &:hover,
  &:focus-visible {
    text-decoration: none;
    color: var(--brand-color);
    background: rgba(0, 0, 0, 0.05);
    --bd-color: var(--brand-color);
  }
`;
