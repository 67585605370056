import React from 'react';
import styled, { css } from 'styled-components';
const Container = styled.div`
  position: relative;
  margin-bottom: 1rem;
  font-size: 0.875rem;

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    ${({ inline }) =>
      inline &&
      css`
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 1.5rem;
        align-items: center;
      `}
  }
`;
const Label = styled.label`
  display: block;
  font-weight: 500;
  cursor: pointer;

  ${({ badge, theme }) =>
    badge &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: 100%;
      padding: 1rem 0.5rem;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(109, 112, 139, 0.5);
      border: 2px solid transparent;
      text-align: center;
      line-height: 1.3;

      input[type='checkbox']:checked ~ & {
        border-color: ${theme.primaryColor};
      }
    `}
`;
export const commonInputStyle = css`
  width: 100%;
  border: solid 1px #ced5e7;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  line-height: 1.6;
  background-color: white;
  appearance: none;
`;
const InputElement = styled.input`
  ${commonInputStyle}
`;
const TextareaElement = styled.textarea`
  ${commonInputStyle}
  resize: vertical;
`;
const CheckboxElement = styled.input`
  ${({ badge }) =>
    badge &&
    css`
      position: absolute;
      opacity: 0;
    `}
`;
const SelectElement = styled.select`
  ${commonInputStyle}

  color: ${({ theme }) => theme.baseFontColor};
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -0.5rem center;
  background-repeat: no-repeat;
  background-size: 11px 8px;
`;
export const FormInput = ({
  label,
  id,
  type = 'text',
  required,
  containerProps,
  inline,
  badge,
  ...props
}) => {
  let Element;

  switch (type) {
    case 'textarea':
      Element = <TextareaElement id={id} required={required} {...props} />;
      break;

    case 'select':
      Element = <SelectElement id={id} required={required} {...props} />;
      break;

    case 'checkbox':
    case 'radio':
      Element = <CheckboxElement badge={badge} id={id} type={type} required={required} {...props} />;
      break;

    default:
      Element = <InputElement id={id} type={type} required={required} {...props} />;
  }

  const isCheckbox = type === 'checkbox' || type === 'radio';
  return (
    <Container inline={inline} {...containerProps}>
      {isCheckbox && Element}
      {!!label && (
        <Label htmlFor={id} badge={badge}>
          {label}
          {!!required && <>&nbsp;*</>}
        </Label>
      )}
      {!isCheckbox && Element}
    </Container>
  );
};
