import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Video } from 'src/components/atoms/Video';
import { InlineList } from 'src/components/molecules/InlineList';
import { Grid, Column } from 'src/components/molecules/Grid';
import { BottomCTA } from 'src/components/organisms/BottomCTA';
import { SEO } from 'src/containers/SEO';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import { Box } from 'src/components/atoms/Box';
import { Icon } from 'src/components/atoms/Icon';
import { Card } from 'src/components/atoms/Card';
import * as React from 'react';
export default {
  Section,
  Button,
  Video,
  InlineList,
  Grid,
  Column,
  BottomCTA,
  SEO,
  GitHubButton,
  Box,
  Icon,
  Card,
  React
};