import React, { Children } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';

export const SystemResets = createGlobalStyle``;

export const SystemRoot = createGlobalStyle`
  :root {
    --row-gap: var(--inner-y-default, 2rem);
    /* --column-gap: var(--inner-x-default, 2rem); */
    --column-span: 1;
    --column-min-width: 0%;
    --column-max-width: 1fr;
    --global-columns: 8;
    --outer-width-xl: 1350px;
    /*  */
    --column-gap: 1rem;
    --outer-x: 1rem;
    --xn-duration: 200ms;
    @media (min-width: 500px) {
      --column-gap: 1.5rem;
      --outer-x: 1.5rem;
    }
    @media (min-width: 800px) {
      --column-gap: 2rem;
      --outer-x: 2rem;
    }
    @media (min-width: 1100px) {
      --column-gap: 2.5rem;
      --outer-x: 2.5rem;
    }

    --brand-color: #5a17ee;
    --footer-background-color: #ffffff;
    --footer-text-color: #6d708b;
    --footer-header-color: var(--alternative-heading-color, #343434);
  }

  .cols-auto { --columns: auto-fit; }
  .cols-auto-fit { --columns: auto-fit; }
  .cols-auto-fill { --columns: auto-fill; }

  [class^="xn-"], [class*=" xn-"] {
    animation-duration: var(--xn-duration);
    animation-fill-mode: both;
    transition-duration: var(--xn-duration);
    transition-property: color, background-color, transform, opacity;
  }
  :root {
    --reach-dialog: 1; // required to avoid warning re: not using their CSS
  }

`;

export const resetList = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const btn = css`
  appearance: none;
  background: none;
  border: none;
`;

export const wrap = css`
  max-width: var(--outer-width, 100%);
  margin: 0 auto;
`;
export const wrapLast = css`
  max-width: var(--outer-width-xl, 100%);
  margin: 0 auto;
`;
export const clip = css`
  overflow: hidden;
`;
export const stop = css`
  padding: 0.05px;
`;
export const frame = css`
  padding-right: var(--outer-x);
  padding-left: var(--outer-x);
`;

export const Wrap = styled.div`
  max-width: var(--outer-width, 100%);
  margin: 0 auto;
`;

const StackFn = ({ children, ...props }) => (
  <div {...props}>{Children.map(children, (child) => child && <div>{child}</div>)}</div>
);

export const stack = css``;

export const Stack = styled(StackFn)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.05px;
  padding-bottom: 0.05px;
  & > * {
    flex: 0 0 auto;
    height: auto;
    width: 100%;
    width: stretch;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > * + * {
    margin-top: var(--gap-y, var(--gap, 10px));
  }
`;

export const Clip = styled.div`
  overflow: hidden;
`;

export const Stop = styled.div`
  padding: 0.05px;
`;

// const McolFn = ({ cols, ...props }) => <div {...props} style={cols ? { '--multi-columns': cols } : {}} />;

export const Mcol = styled.div`
  column-fill: balance;
  column-width: auto;
  column-count: var(--multi-columns, 1);
  column-gap: var(--column-gap);
  perspective: 1; // create new stacking context; see issues https://caniuse.com/#feat=multicolumn
  & > * {
    background-clip: content-box; // so gutters remain clear
    // column/section/page fragmentation:
    // see https://www.smashingmagazine.com/2019/02/css-fragmentation/
    column-span: none; // will not be used
    box-decoration-break: clone;
    break-before: auto;
    break-after: auto;
    orphans: 2;
    widows: 2;
  }
  & > * {
    // --column-span: calc(var(--column-span-proxy, var(--global-columns)) / var(--columns, 1));
    --columns: calc(var(--proxy-columns, var(--global-columns)) / var(--multi-columns, 1));
  }
`;

export const McolGrid = styled(Mcol)`
  margin-top: calc(var(--row-gap) / -2) !important;
  margin-bottom: calc(var(--row-gap) / -2) !important;
  & > * {
    // don't break inside; treat as blocks
    display: block;
    // page-break-inside: avoid;
    // break-inside: avoid-column;
    break-inside: avoid;
    padding-top: calc(var(--row-gap) / 2) !important;
    padding-bottom: calc(var(--row-gap) / 2) !important;
  }
`;

export const Flow = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + var(--gap-x, var(--gap, 10px)));
  margin-left: calc(var(--gap-x, var(--gap, 10px)) / -2);
  margin-right: calc(var(--gap-x, var(--gap, 10px)) / -2);
  margin-top: calc(var(--gap-y, var(--gap, 10px)) / -2);
  margin-bottom: calc(var(--gap-y, var(--gap, 10px)) / -2);
  & > * {
    flex: 0 0 auto;
    width: max-content;
    max-width: 100%;
    min-width: 0%;
    min-height: 1px;
    background-clip: content-box;
    padding-left: calc(var(--gap-x, var(--gap, 10px)) / 2);
    padding-right: calc(var(--gap-x, var(--gap, 10px)) / 2);
    padding-top: calc(var(--gap-y, var(--gap, 10px)) / 2);
    padding-bottom: calc(var(--gap-y, var(--gap, 10px)) / 2);
  }
`;

export const FlowGrid = styled(Flow)`
  --gap-y: var(--row-gap, var(--grid-gap));
  --gap-x: var(--column-gap, var(--grid-gap));
  & > * {
    width: var(--column-width);
    --column-width: calc(99.99999% * var(--column-span, 1) / var(--columns, 1));
    max-width: 100%;
    min-width: 0%;
  }
  & > .span-auto {
    flex: 1 1 auto;
    --column-width: 0;
  }
`;

export const Grid = styled.div`
  --column-min-width: 0%;
  --column-max-width: 1fr;
  display: grid;
  --grid-gap: var(--row-gap) var(--column-gap);
  grid-gap: var(--grid-gap);
  gap: var(--grid-gap); // where supported
  --column-width: minmax(var(--column-min-width), var(--column-max-width));
  --column-width: minmax(min(var(--column-min-width), 100%), var(--column-max-width)); // where supported
  grid-template-columns: repeat(var(--columns, 1), var(--column-width));
  grid-template-rows: max-content;
  &.dense {
    grid-auto-flow: row dense;
  }
  > * {
    grid-column: var(--column);
    --column: var(--column-start) / var(--column-end);
    --column-start: span var(--column-span, 1);
    --column-end: span var(--column-span, 1);
    grid-row: var(--row);
    --row: var(--row-start) / var(--row-end);
    --row-start: span var(--row-span, 1);
    --row-end: span var(--row-span, 1);
    max-width: stretch;
    &.start-null {
      --column-start: span var(--column-span);
    }
    &.end-null {
      --column-end: span var(--column-span);
    }
    &.row-start-null {
      --row-start: span var(--row-span);
    }
    &.row-end-null {
      --row-end: span var(--row-span);
    }
  }
  /* NB: auto-fit is the default "auto" count */
  &.cols-auto {
    --columns: auto-fit;
  }
  &.cols-auto-fit {
    --columns: auto-fit;
  }
  &.cols-auto-fill {
    --columns: auto-fill;
  }
`;

const outerStyle = {
  position: 'relative',
  display: 'block',
};

const innerStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const textStyle = {
  fontSize: '14px',
  fontFamily: 'inherit',
  textAlign: 'center',
};

export const Ph = ({ children, bg, h, w, aspect, style, ...restProps }) => {
  return (
    <div
      style={{
        width: `${w ? `${w}px` : `${100}%`}`,
        ...style,
      }}
      {...restProps}
    >
      <div
        style={{
          ...outerStyle,
          color: 'black',
          backgroundColor: `${bg || 'rgba(0,0,0,0.1)'}`,
          [h ? 'height' : 'paddingBottom']: `${h ? `${h}px` : `${100 / (aspect || 1)}%`}`,
        }}
      >
        <div style={innerStyle}>
          <div style={textStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const Dump = ({ data }) => (
  <pre>
    <code>{typeof data !== 'string' ? JSON.stringify(data, null, 2) : data}</code>
  </pre>
);
