import React, { useRef, useState } from 'react';
import HeaderColorState from './HeaderColorState';
import { useIsomorphicLayoutEffect } from 'src/helpers/useIsomorphicLayoutEffect';

/*
  TODO: make a hook that tracks simple visibility, based on this code
  full: true|false
  part: true|false
  none: true|false
*/

export function PageHeader({
  primary,
  dark,
  alternative,
  children,
  brandColor,
  headerColor,
  footerTextColor,
  footerBackgroundColor,
  footerHeaderColor,
  ...props
}) {
  const headerRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  useIsomorphicLayoutEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        (records) => {
          for (const record of records) {
            setIsHeaderVisible(record.intersectionRatio > 0.1);
            setIsHeaderScrolled(record.intersectionRatio < 0.9);
          }
        },
        {
          threshold: [0.1, 0.9],
        },
      );
      observer.observe(headerRef.current);
      return () => observer.unobserve(headerRef.current);
    }
  }, [headerRef]);
  return (
    <div className="page-header" ref={headerRef} {...props}>
      <HeaderColorState
        {...{
          alternative,
          primary,
          dark,
          visible: isHeaderVisible,
          scrolled: isHeaderScrolled,
          brandColor,
          headerColor,
          footerTextColor,
          footerBackgroundColor,
          footerHeaderColor
        }}
      />
      {children}
    </div>
  );
}
