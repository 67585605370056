import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Link } from 'src/containers/Link';
import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import docsLogo from 'src/images/docs/logo-docs.svg';

const Container = styled.div`
  background-color: white;
  color: #1c1e21;
  z-index: 100;
  position: relative;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  font-size: 1rem;
  font-feature-settings: 'kern';
  font-kerning: normal;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    height: ${props => props.openOnMobile ? '100%' : 'initial'};
    position: ${props => props.openOnMobile ? 'absolute' : 'initial'};
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1264px;
  height: 3.75rem;
  font-weight: 500;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    display: ${props => props.openOnMobile ? 'block' : 'flex'};
    align-items: ${props => props.openOnMobile ? 'left' : 'center'};
  }
`;
const Navigation = styled.ul`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    :not(:last-child) {
      margin: 0;
    }

    a:not([class]) {
      display: inline-block;
      text-decoration: none;
      color: #1c1e21;
      padding: 0.5rem 1rem;

      &:hover {
        color: #5a17ee;
      }
    }
  }

  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    align-items: ${props => props.openOnMobile ? 'left' : 'initial'};
    display: ${props => props.openOnMobile ? 'block' : 'initial'};
    > li {
      a:not([class]) {
        display: ${props => props.openOnMobile ? 'inline-block' : 'none'};
      }
    }
  }
`;

const LogoLink = styled.a`
  display: block;
  border-top: 3px solid transparent;
  color: #5a17ee;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 85%;
  margin-right: 25.5px;

  img {
    width: 125px;
    height: 45px;
  }
`;
const GithubIcon = styled(Icon)`
  font-size: 1.5rem;
`;
const DropdownButton = styled(Button)`
  border-width: 1px;
  padding-top: 0.55rem 1.9rem;
  font-size: 0.9em;
  border-color: #ddd;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  letter-spacing: 0.0429px;
`;

const EllipsisButtonContainer = styled.button`
  border-width: 0px;
  display: none;
  background-color: #fff;
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    display: ${props => props.openOnMobile ? 'none' : 'inline-block'};
  }
`;

const DropdownIcon = styled(Icon)`
  margin-left: 6px;
  margin-right: 1px;
`;
const DropdownContainer = styled.div`
  position: relative;

  ul {
    display: none;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    font-size: 87.5%;

    a {
      color: #5a17ee;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  &:hover {
    ${DropdownButton} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    ul {
      display: block;
    }
  }

  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    display:  ${props => props.openOnMobile ? 'inline-block' : 'none'};
  }
`;

const CloseBtnContainer = styled.button`
border-width: 0px;
padding-top: 0.75rem;
padding-right: 1rem;
display: none;
background-color: #fff;
font-size: 1.2em;
-webkit-font-smoothing: antialiased;
font-smooth: antialiased;
@media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
  position: absolute;
  top: 0;
  right: 0;
  display: ${props => props.openOnMobile ? 'block' : 'none'};
}
`;

const Divider = styled.hr`
display: none;
@media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: ${props => props.openOnMobile ? 'block' : 'none'};
}
`

export const DocsHeader = () => {

  const [openOnMobile, setOpenOnMobile] = useState(false)
  return (
    <Container openOnMobile={openOnMobile}>
      <Content openOnMobile={openOnMobile}>
        <Navigation openOnMobile={openOnMobile}>
          <li>
            <LogoLink href="/docs/">
              <img src={docsLogo} width="80px" height="40px" title="Rasa logo" alt="Rasa logo" />
            </LogoLink>
          </li>
          <li>
            <a href="/docs/rasa-pro/">Rasa Pro</a>
          </li>
          <li>
            <a href="/docs/studio/">Rasa Studio</a>
          </li>
          <li>
            <a href="/docs/rasa/">Rasa Open Source</a>
          </li>
          <li>
            <a href="/docs/rasa-enterprise/">Rasa X/Enterprise</a>
          </li>
        </Navigation>
        <Divider />
        <Navigation openOnMobile={openOnMobile}>
          <li>
            <a href="https://github.com/rasaHQ/">
              <GithubIcon icon={['fab', 'github']} />
            </a>
          </li>
          <li>
            <Link to="/blog/">Blog</Link>
          </li>
          <li>
            <DropdownContainer openOnMobile={openOnMobile}>
              <DropdownButton type="button" secondary noMargin>
                Community
                <DropdownIcon icon={['fas', 'caret-down']} />
              </DropdownButton>
              <ul>
                <li>
                  <Link href="https://rasa.community/">Community Hub</Link>
                </li>
                <li>
                  <Link href="https://rasa.community/join">Forum</Link>
                </li>
                <li>
                  <Link href="https://rasa.community/contribute/">How to Contribute</Link>
                </li>
                <li>
                  <Link href="https://rasa.community/showcase/">Community Showcase</Link>
                </li>
              </ul>
            </DropdownContainer>
          </li>
          <li >
            <EllipsisButtonContainer openOnMobile={openOnMobile} onClick={() => setOpenOnMobile(true)}>
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
              </EllipsisButtonContainer>
          </li>
        </Navigation>
        <CloseBtnContainer openOnMobile={openOnMobile} onClick={() => setOpenOnMobile(false)}>
          <FontAwesomeIcon icon={faTimes} size="lg"/>
        </CloseBtnContainer>
      </Content>
    </Container>
  );
};
