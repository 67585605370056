import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/containers/Link';
import { Icon } from 'src/components/atoms/Icon';
const Container = styled.div`
  margin-bottom: 1.5rem;
`;
const Items = styled.div``;
const Action = styled(Link)`
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1.5rem;
  margin-top: 1rem;
  padding-top: calc(1rem - 1px);
  border-top: 1px solid #eef1f6;
  font-weight: bold;
  text-decoration: underline;
  color: inherit;
`;
export const TeaserList = ({ children, action, actionLabel }) => (
  <Container>
    <Items>{children}</Items>
    {!!action && (
      <Action to={action}>
        <span>{actionLabel ?? 'Read now'}</span>
        <Icon icon="arrow-right" />
      </Action>
    )}
  </Container>
);
const ItemContainer = styled(({ to, bordered, ...props }) =>
  to ? <Link to={to} {...props} /> : <div {...props} />,
)`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  align-items: center;
  color: inherit;
  text-decoration: none;
  overflow: hidden;

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid rgba(227, 231, 239, 0.41);
      box-shadow: 0 2px 4px 0 rgba(47, 17, 92, 0.1);
      border-radius: 0.33rem;

      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        transform: translateY(-0.25rem);
      }
    `}

  &:hover {
    text-decoration: none;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    margin-bottom: calc(1rem - 1px);
    border-bottom: 1px solid #eef1f6;
  }
`;
const ItemContent = styled.div`
  padding: 0 1rem;

  h4 {
    margin-bottom: 0.25rem;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;
export const TeaserListItem = ({ to, image, children, bordered }) => (
  <ItemContainer bordered={bordered} to={to}>
    {image ?? <div />}
    <ItemContent>{children}</ItemContent>
  </ItemContainer>
);
