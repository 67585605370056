import styled, { css } from 'styled-components';
export const Card = styled.div`
  border-radius: 0.95rem;
  color: ${({ customTextColor, theme }) =>
    customTextColor ? customTextColor  : theme.baseFontColor
  };
  background-color: ${({ customBackgroundColor }) =>
    customBackgroundColor ? customBackgroundColor  : 'var(--card-color, #fff)'
  };
  margin: 0 0 1.75rem;
  box-shadow: 0 2px 4px 0 rgba(109, 112, 139, 0.1);
  --primary-color: ${({ theme }) => theme.primaryColor};
  --secondary-color: white;
  --alternative-heading-color: ${({ theme }) => theme.baseHeaderColor};
  overflow: hidden;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  height: ${({ expandedHeightEnabled }) => (expandedHeightEnabled ? '100%' : 'unset')};
  min-height: ${({ collapsedHeight }) => (collapsedHeight ? collapsedHeight : 'unset')};

  ${({ centered }) => centered && 'text-align: center;'}
  ${({ noPadding, small }) =>
    !noPadding &&
    (small
      ? css`
          padding: 2rem 1.5rem;
        `
      : css`
          padding: 3rem 2rem;
        `)}

  ${({ primary, theme }) =>
    primary &&
    css`
      --primary-color: white;
      --secondary-color: #343434;
      --alternative-heading-color: white;
      background-color: ${theme.primaryColor};
      color: white;
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      --primary-color: white;
      --secondary-color: #343434;
      --alternative-heading-color: white;
      background-color: var(--card-color--secondary, #293953);
      color: white;
    `}

  ${({ tertiary }) =>
    tertiary &&
    css`
      background-color: transparent;
      box-shadow: none;
      border: 1px solid #e1e1e1;
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 1rem;
    `}

  ${({ flexRow }) =>
    flexRow &&
    css`
      display: flex;
      align-items: center;
      gap: 1rem;
    `}

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-direction: column;
    `}
  ${({ flat }) =>
    flat &&
    css`
      background-color: transparent;
      box-shadow: none;
      border: none;
    `}
  ${({ zeroPadding }) =>
    zeroPadding &&
    css`
      padding: 0;
    `}

  > *:last-child {
    margin-bottom: 0;
  }
`;
Card.displayName = 'Card';
