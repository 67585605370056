import { graphql, useStaticQuery } from 'gatsby';

export function useSiteHeaderNavLinks() {
  const data = useStaticQuery(graphql`
    query {
      allSiteHeaderNavYaml {
        nodes {
          text
          href
          cta
          items {
            text
            href
            indent
            items {
              text
              href
            }
          }
        }
      }
      allOpensourceHeaderNavYaml {
        nodes {
          text
          href
          cta
          items {
            text
            href
            # items {
            #   text
            #   href
            # }
          }
        }
      }
    }
  `);

  return process.env.GATSBY_WEBSITE_TYPE === 'opensource'
    ? data.allOpensourceHeaderNavYaml.nodes
    : data.allSiteHeaderNavYaml.nodes;
}
