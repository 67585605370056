const outerStyle = {
  position: 'relative',
  display: 'block',
};

const innerStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const textStyle = {
  fontSize: '14px',
  fontFamily: 'inherit',
  textAlign: 'center',
};

export const Ph = ({ children, bg, h, w, aspect, style, ...restProps }) => {
  return (
    <div
      style={{
        width: `${w ? `${w}px` : `${100}%`}`,
        ...style,
      }}
      {...restProps}
    >
      <div
        style={{
          ...outerStyle,
          color: `${bg && contrast(bg) == 'dark' ? 'white' : 'black'}`,
          backgroundColor: `${bg || 'rgba(0,0,0,0.1)'}`,
          [h ? 'height' : 'paddingBottom']: `${h ? `${h}px` : `${100 / (aspect || 1)}%`}`,
        }}
      >
        <div style={innerStyle}>
          <div style={textStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const Dump = ({ data }) => (
  <pre>
    <code>{typeof data !== 'string' ? JSON.stringify(data, null, 2) : data}</code>
  </pre>
);
