import React, { Children, cloneElement, forwardRef } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { reduceSystemProps, setPropFallback } from 'src/system2';

const StackEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.05px;
  padding-bottom: 0.05px;
  & > * {
    flex: 0 0 auto;
    height: auto;
    width: 100%;
    width: stretch;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Stack = forwardRef(function Stack(
  // eslint-disable-next-line no-unused-vars
  { as, children, sx: { gap, gapY = gap, gapX, ...sx } = {}, style, className, ...props },
  fwdRef,
) {
  const childProps = reduceSystemProps({ mt: gapY ? setPropFallback(gapY, 10) : 10 });
  const parentProps = { as, ...reduceSystemProps({ ...sx, style, className }), ...props };
  return (
    <StackEl {...parentProps} ref={fwdRef}>
      {Children.map(children, (child, i) =>
        child
          ? i > 0
            ? cloneElement(child, {
                className: clsx(childProps.className, child.props?.className),
                style: { ...childProps.style, ...child.props?.style },
              })
            : child
          : null,
      )}
    </StackEl>
  );
});

const ChainEl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  width: auto;
  & > * {
    flex: 0 0 auto;
    width: max-content;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const Chain = forwardRef(function Chain(
  // eslint-disable-next-line no-unused-vars
  { as, children, sx: { gap, gapX = gap, gapY, ...sx } = {}, style, className, ...props },
  fwdRef,
) {
  const childProps = reduceSystemProps({ ml: gapX ? setPropFallback(gapX, 10) : 10 });
  const parentProps = { as, ...reduceSystemProps({ ...sx, style, className }), ...props };
  return (
    <ChainEl {...parentProps} ref={fwdRef}>
      {Children.map(children, (child, i) =>
        child
          ? i > 0
            ? cloneElement(child, {
                className: clsx(childProps.className, child.props?.className),
                style: { ...childProps.style, ...child.props?.style },
              })
            : child
          : null,
      )}
    </ChainEl>
  );
});
