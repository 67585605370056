import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { LazyMotion, domAnimation } from 'framer-motion';

import { GlobalStyle, theme } from './src/styles/styled-components';
import SiteLayout from './src/layouts/Site';
import { SystemResets, SystemRoot } from './src/system';
import './static/assets/css/klaro.css';
config.autoAddCss = false;

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  const Layout =
    element?.type?.layout ?? (props.location?.pathname?.startsWith('/docs/') ? React.Fragment : SiteLayout);

  return (
    <LazyMotion features={domAnimation} strict>
      <Layout {...props}>
        <SystemResets />
        <SystemRoot />
        <GlobalStyle />
        {element}
      </Layout>
    </LazyMotion>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!location || !prevLocation) return;

  if (location.pathname === prevLocation.pathname) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  const previous_path = prevLocation ? prevLocation.pathname : null;

  window.dataLayer.push({
    event: 'route-update',
    page: location.pathname,
    url: location.href,
    previous_path,
  });
};
