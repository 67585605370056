import styled, { css } from 'styled-components';
export const InlineList = styled.ul`
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  margin: ${({ noMargin, small }) =>
    noMargin ? (small ? '0 -.8rem' : '0 -0.875rem') : small ? '0 -.8rem .75rem' : '0 -0.875rem 0.75rem'};
  padding: 0;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}

  > li {
    margin: ${({ small, spacious }) =>
      small ? '0 0.8rem 1rem' : spacious ? '0 2.5rem 1rem' : '0 0.875rem 1rem'};
  }
`;
InlineList.displayName = 'InlineList';
