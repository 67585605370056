import React from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/atoms/Icon';
const Container = styled.div`
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;
const Panel = styled.details`
  margin: 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  summary {
    &:focus {
      outline: 0;
    }

    /** Hide marker */

    list-style-type: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::marker {
      display: none;
    }
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  background: none;
  border: 0;
  width: 100%;
  color: var(--alternative-heading-color, #343434);
  text-align: left;
  font-weight: bold;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;
const HeaderContent = styled.div`
  flex: 1;
`;
const HeaderIcon = styled(Icon)`
  margin-left: auto;
  padding-left: 1rem;
  ${Panel}[open] & {
    transform: scaleY(-1);
  }
`;
const IconContainer = styled.div`
  margin-right: 0.5rem;
  width: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.6;
`;
const Content = styled.div`
  padding: 0 2.5rem;
  color: ${({ theme }) => theme.baseFontColor};
  font-size: 1rem;
  overflow: hidden;
`;
export const Accordion = ({ sections = [] }) => (
  <Container>
    {sections.map((section, i) => (
      <Panel key={i}>
        <summary>
          <Header>
            {section.icon && <IconContainer>{section.icon}</IconContainer>}
            <HeaderContent>{section.title}</HeaderContent>
            <HeaderIcon icon="chevron-down" size="1x" />
          </Header>
        </summary>

        <Content data-content>{section.content}</Content>
      </Panel>
    ))}
  </Container>
);
