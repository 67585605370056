import { Location } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { loadScript } from '../../helpers/loadScript';
import { useIsomorphicLayoutEffect } from '../../helpers/useIsomorphicLayoutEffect';
import { commonInputStyle } from './FormInput';
const HUBSPOT_ID = 6711345;
/**
 * Load the hubspot CTA embed script if it's not loaded yet and initialize the CTA
 * @param id
 */

async function initializeForm({ formId, sfdcCampaignId, adConversionLabel }) {
  //@ts-ignore
  const hasForms = window?.hbspt?.forms;

  if (!hasForms) {
    await loadScript('https://js.hsforms.net/forms/v2.js');
  }

  window.dataLayer = window.dataLayer || []; //@ts-ignore

  window.hbspt.forms.create({
    portalId: HUBSPOT_ID,
    formId,
    sfdcCampaignId,
    target: `#hbspt-form--${formId}`,

    onFormSubmitted() {
      if (adConversionLabel) {
        //@ts-ignore
        window.dataLayer.push({
          event: 'Ad Conversion',
          adConversionLabel,
        });
      }
    },
  });
}

const HubspotFormContainer = styled.div`
  font-size: 0.875rem;

  select,
  textarea {
    display: block;
    width: 100% !important;
  }

  [class^='form-columns-'],
  [class*=' form-columns-'] {
    display: flex;
    flex-flow: wrap;
    max-width: calc(100% + 1.5rem);
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    > .hs-fieldtype-text {
      width: 100% !important;
    }
    > * {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    &.form-columns-1 > * {
      width: 100% !important;
    }

    &.form-columns-2 > * {
      width: calc(50%) !important;
    }

    &.form-columns-3 > * {
      width: calc(33.3333%) !important;
    }

    &.form-columns-4 > * {
      width: calc(25%) !important;
    }
  }

  .hs-form-field {
    margin-bottom: 1.5rem;

    .hs-error-msgs {
      margin-top: 0.5rem;
    }
  }

  .input {
    margin: 0;
    width: 100%;
  }

  .hs-input:not([type='checkbox']) {
    ${commonInputStyle}
  }

  .hs-form-required:before {
    content: ' ';
  }

  .inputs-list {
    padding: 0;
    list-style: none;
  }

  .hs-error-msgs {
    margin: 0;
    border: 1px solid #fbb;
    border-radius: 8px;
    background-color: #fee;
    list-style: none;
    color: #444;
    padding: 0.5rem 0.75rem;
  }

  .hs-error-msg {
    font-weight: normal;
  }

  .hs_error_rollup {
    margin-bottom: 1.5rem;
  }

  .hs-form-checkbox {
    margin-bottom: 0.3rem;
  }

  .hs-form-checkbox input {
    margin-right: 0.5rem;
  }

  .hs-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color, ${({ theme }) => theme.primaryColor});
    border-radius: 8px;
    border: 2px solid var(--primary-color, ${({ theme }) => theme.primaryColor});
    padding: 0.5625rem 1.375rem;
    font-size: ${({ theme }) => theme.smallFontSize};
    font-weight: 500;
    line-height: inherit;
    color: var(--secondary-color, white);
    text-decoration: none;
    vertical-align: baseline;
    cursor: pointer;
    transition: all 0.1s ease;
    -webkit-font-smoothing: auto;
    margin-bottom: 1.5rem;

    &:hover,
    &:active {
      opacity: 0.9;
    }
  }

  .hs-submit .hs-button {
    width: 100%;
    margin-bottom: 0;
  }
  ul.inputs-list {
    margin: 0;
  }

  label.hs-form-booleancheckbox-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    > input {
      flex: 0 0 auto;
      width: min-content !important;
    }
    > span {
      margin-left: 1em;
    }
  }

  label {
    display: block;
    font-weight: 500;
    cursor: pointer;
  }

  .submitted-message {
    background-color: #e7fbde;
    color: #397f1c;
    border-radius: 12px;
    padding: 0.5rem 1rem;

    p {
      margin: 0.5rem 0;
    }
  }

  .legal-consent-container .hs-error-msgs label {
    color: inherit;
  }

  ${({ customStyles }) => css`${customStyles}`}
`;

const HubspotFormInitializer = ({
  formId,
  sfdcCampaignId,
  adConversionLabel,
  stacked,
  className,
  customStyles
}) => {
  const containerRef = useRef();
  const [initialized, setInitialized] = useState(false);
  useIsomorphicLayoutEffect(() => {
    if (!initialized) {
      if (typeof window.IntersectionObserver !== 'undefined') {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                initializeForm({ formId, sfdcCampaignId, adConversionLabel });
                setInitialized(true);
              }
            });
          },
          {
            root: null,
            rootMargin: '200px',
          },
        );
        observer.observe(containerRef.current);
        return () => observer.unobserve(containerRef.current);
      } else {
        initializeForm({ formId, sfdcCampaignId, adConversionLabel });
      }
    }
  }, [formId, adConversionLabel, containerRef, initialized]);
  return (
    <HubspotFormContainer
      stacked={stacked}
      customStyles={customStyles}
      ref={containerRef}
      id={`hbspt-form--${formId}`}
      className={className}
    />
  );
};

export const HubspotForm = ({
  formId,
  sfdcCampaignId,
  className,
  stacked,
  customStyles
}) => {
  const data = useStaticQuery(graphql`
    {
      allConversionsJson {
        nodes {
          pathname
          event
          label
        }
      }
    }
  `);
  return (
    <Location>
      {({ location }) => {
        const conversion = Array.from(data.allConversionsJson.nodes).find((conversion) => {
          return conversion.event === 'hubspot-form-submit' && conversion.pathname === location.pathname;
        });
        return (
          <HubspotFormInitializer
            formId={formId}
            sfdcCampaignId={sfdcCampaignId}
            adConversionLabel={conversion && conversion.label}
            className={className}
            stacked={stacked}
            customStyles={customStyles}
          />
        );
      }}
    </Location>
  );
};
