import React from 'react';
import clsx from 'clsx';
import { Image as DatoImage } from 'react-datocms';

import { parseBoolean, parseArString, imgixSrc, imgixSrcSet } from './utilities';

let Head = () => null;

export function setup({ headComponent }) {
  if (!Head) Head = headComponent;
}

/*
  API
    see also https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image#shared-props

  priority
    - results in `loading='eager'` on img (default is `loading=lazy`)
    - results in `<link rel='preload' as='image'...` in `<head>`
  constrain (Fluid only)
    - limits the image to maxWidth if specified or natural width
    - implicit, if maxWidth is given

  PROBLEMS
    - fluid mode generates float width/height as ratios
    - how to implement constrain?
    - gif images will not generate webpSrcSet -- what happens??
 */

export function FixedImg({
  // as = 'div',
  data,
  width = 300, // default to standard embed size
  height,
  aspectRatio,
  cropFocus,
  priority,
  fadeIn = 250,
  inline,
  className,
  lqip = 'blur', // 'blur' | Number (index on colors[]),
  style,
  ...restProps
}) {
  const imageData = parseToDatoImage(data, { layout: 'fixed', width, height, cropFocus, aspectRatio, lqip });
  const { src, srcSet, sizes } = imageData;
  return (
    <>
      {priority && (
        <Head>
          <link rel="preload" as="image" href={src} imagesrcset={srcSet} imagesizes={sizes} />
        </Head>
      )}
      <DatoImage
        data={imageData}
        lazyLoad={true} // Boolean | optional | Wheter enable lazy loading or not	true
        fadeInDuration={fadeIn} // integer | optional | Duration (in ms) of the fade-in transition effect upoad image loading	500
        explicitWidth={width} // Boolean | optional | Wheter the image wrapper should explicitely declare the width of the image or keep it fluid	false
        style={{
          ...style, // spread this first, so our styles override
          display: inline ? 'inline-block' : 'block',
          width,
          maxWidth: '100%',
        }} // object | optional | Additional CSS rules to add to the root node	null
        className={clsx(className, `dato-img fixed-img`)} // string | optional | Additional CSS className for root node	null
        // pictureClassName={undefined} // string | optional | Additional CSS class for the image inside the inner <picture /> tag	null
        // pictureStyle={undefined} // object | optional | Additional CSS rules to add to the image inside the inner <picture /> tag	null
        // intersectionTreshold={undefined} // float | optional | Indicate at what percentage of the placeholder visibility the loading of the image should be triggered. A value of 0 means that as soon as even one pixel is visible, the callback will be run. A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.	0
        // intersectionMargin={undefined} // string | optional | Margin around the placeholder. Can have values similar to the CSS margin property (top, right, bottom, left). The values can be percentages. This set of values serves to grow or shrink each side of the placeholder element's bounding box before computing intersections.	"0px 0px 0px 0px"
        {...restProps}
      />
    </>
  );
}

/*

 */
export function FluidImg({
  // as = 'div',
  data,
  aspectRatio,
  cropFocus,
  maxWidth = 1920,
  constrain,
  priority,
  fadeIn = 250,
  inline,
  className,
  lqip = 'blur', // 'blur' | Number (index on colors[]),
  style,
  ...restProps
}) {
  const imageData = parseToDatoImage(data, {
    layout: 'fluid',
    maxWidth,
    cropFocus,
    aspectRatio,
    constrain,
    lqip,
  });
  const { src, srcSet, sizes } = imageData;

  // parse constrain vs maxWidth
  if (!constrain) constrain = !!maxWidth; // force constrain to true if maxWidth was defined
  if (!maxWidth) maxWidth = data.width; // let maxWidth fall back to nativeWidth

  return (
    <>
      {priority && (
        <Head>
          <link rel="preload" as="image" href={src} imagesrcset={srcSet} imagesizes={sizes} />
        </Head>
      )}
      <DatoImage
        data={imageData}
        lazyLoad={true} // Boolean | optional | Wheter enable lazy loading or not	true
        fadeInDuration={fadeIn} // integer | optional | Duration (in ms) of the fade-in transition effect upoad image loading	500
        explicitWidth={constrain ? maxWidth : undefined} // Boolean | optional | Wheter the image wrapper should explicitely declare the width of the image or keep it fluid	false
        style={{
          ...style, // spread this first, so our styles override
          display: inline ? 'inline-block' : 'block',
          width: constrain ? maxWidth : undefined,
          maxWidth: '100%',
        }} // object | optional | Additional CSS rules to add to the root node	null
        className={clsx(className, `dato-img fluid-img`)} // string | optional | Additional CSS className for root node	null
        // pictureClassName={undefined} // string | optional | Additional CSS class for the image inside the inner <picture /> tag	null
        // pictureStyle={undefined} // object | optional | Additional CSS rules to add to the image inside the inner <picture /> tag	null
        // intersectionTreshold={undefined} // float | optional | Indicate at what percentage of the placeholder visibility the loading of the image should be triggered. A value of 0 means that as soon as even one pixel is visible, the callback will be run. A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.	0
        // intersectionMargin={undefined} // string | optional | Margin around the placeholder. Can have values similar to the CSS margin property (top, right, bottom, left). The values can be percentages. This set of values serves to grow or shrink each side of the placeholder element's bounding box before computing intersections.	"0px 0px 0px 0px"
        {...restProps}
      />
    </>
  );
}

/*
  parsing logic
  - fixed or fluid
  - fluid AND constrain
  - if original format is gif, don't generate webp

*/
export function parseToDatoImage(
  {
    url,
    alt,
    title,
    width: nativeWidth,
    height: nativeHeight,
    format: nativeFormat,
    customData,
    focalPoint,
    resolutions,
    responsiveImage,
    colors,
  } = {},
  {
    // options
    layout = 'fluid', // fluid | fixed | constrain
    lqip = 'blur', // 'blur' | Number (index on colors[])
    width,
    height,
    maxWidth,
    cropFocus,
    aspectRatio,
    constrain,
    format,
  } = {},
) {
  // parse ar value
  const { aspectRatio: nativeAspect = nativeWidth / nativeHeight, base64 } =
    resolutions || responsiveImage || {};
  const useCustomAspect = customData?.aspect !== 'Original' && parseBoolean(customData?.doCrop);
  const ar =
    aspectRatio || (useCustomAspect ? customData.aspect : width && height ? `${width}:${height}` : undefined);

  // collect imgix parameters
  const imgixParams = {
    ar, // will be undefined if `ar` not resolved
    fpX: ar && focalPoint?.x, // will be undefined if `ar` not resolved
    fpY: ar && focalPoint?.y, // will be undefined if `ar` not resolved
    crop: cropFocus,
    fm: format !== nativeFormat && format, // will come through only if not the same as native
    auto: format !== nativeFormat && nativeFormat !== 'gif' ? 'format' : undefined,
  };

  // parse out numeric values
  const aspectValue = parseArString(ar) || nativeAspect;

  // parse constrain vs maxWidth
  if (!constrain) constrain = !!maxWidth; // force constrain to true if maxWidth was defined
  if (!maxWidth) maxWidth = nativeWidth; // let maxWidth fall back to nativeWidth

  // sizes: restrict if layout=fixed or constrain=true; otherwise 100vw
  const sizes =
    layout == 'fixed'
      ? `${width}px`
      : constrain
      ? `(min-width: ${maxWidth}px) ${maxWidth}px, 100vw`
      : '100vw';
  const src = imgixSrc(url, { w: layout == 'fixed' ? width : 640, ...imgixParams });
  const srcSet = imgixSrcSet(
    url,
    imgixParams,
    layout == 'fixed'
      ? {
          minWidth: width,
          incWidth: width,
          maxWidth: width * 2,
        }
      : {
          maxWidth: maxWidth * 2,
        },
  );

  // format for datocms Image component
  return {
    /*
    alt
    title
    bgColor
    base64
    src
    sizes
    srcSet
    webpSrcSet
    width
    height
    aspectRatio
  */
    alt,
    title,
    src, // if layout == fixed, this is 1x resolution
    srcSet,
    // ...(webpSrc ? { webpSrc } : {}),
    // ...(webpSrcSet ? { webpSrcSet } : {}),
    sizes,
    width: layout == 'fixed' ? width : constrain ? maxWidth : 1,
    height:
      layout == 'fixed'
        ? height || width / aspectValue
        : constrain
        ? maxWidth / aspectValue
        : 1 / aspectValue,
    aspectRatio: nativeAspect,
    ...(lqip == 'blur'
      ? { base64 }
      : {
          bgColor: colors[lqip].hex,
        }),
  };
}
