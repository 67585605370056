import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'src/containers/Link';
import { Icon } from 'src/components/atoms/Icon';
import { Section } from './Section';

const ExternalLink = styled(Link)`
  color: #03c3ff;
`;
const Container = styled.div`
  width: 100%;
  font-size: 14px;
  color: #617287;
  border-top: 1px solid #e8e8e8;
  padding: 24px 0;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;

  a:not([class]) {
    color: inherit;
  }
`;
const Row = styled.div`
  margin-bottom: 0.75rem;
  ${({ large }) =>
    large &&
    css`
      font-size: 1.25rem;
    `}
`;
const IconLink = styled(Link)`
  font-size: 1.25rem;
  color: inherit;
`;
export const DocsFooter = () => (
  <Section noPadding maxWidth="xlarge">
    <Container>
      <Row>
        Stuck?{' '}
        <ExternalLink to="https://forum.rasa.com" target="_blank">
          Ask a Question
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink to="https://github.com/rasahq/rasa/issues" target="_blank">
          Create an Issue
        </ExternalLink>
      </Row>
      <Row large>
        <IconLink to="https://github.com/RasaHQ" target="_blank" title="GitHub">
          <Icon icon={['fab', 'github']} />
        </IconLink>{' '}
        <IconLink to="https://stackoverflow.com/search?q=rasa" target="_blank" title="Stack Overflow">
          <Icon icon={['fab', 'stack-overflow']} />
        </IconLink>{' '}
        <IconLink
          to="https://www.youtube.com/channel/UCJ0V6493mLvqdiVwOKWBODQ"
          target="_blank"
          title="YouTube"
        >
          <Icon icon={['fab', 'youtube']} />
        </IconLink>{' '}
        <IconLink to="https://twitter.com/rasa_hq" target="_blank" title="Twitter">
          <Icon icon={['fab', 'twitter']} />
        </IconLink>
      </Row>

      <Row>
        ©2024 - Rasa Technologies | <Link to="/imprint/">Imprint</Link> |{' '}
        <Link to="/privacy-policy/">Privacy Policy</Link>
      </Row>
    </Container>
  </Section>
);
