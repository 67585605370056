import { graphql, useStaticQuery } from 'gatsby';

export function useTeamData() {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPerson(filter: { personType: { eq: "Rasa" } }, limit: 200) {
        nodes {
          image {
            ...GatsbyDatoCmsImageCustom
          }
          role
          name
        }
      }
      allTeamJson {
        nodes {
          title
          name
          image
        }
      }
    }
  `);

  return data.allDatoCmsPerson.nodes;
}
