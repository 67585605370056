import styled, { css } from 'styled-components';
export const HighlightedText = styled.div`
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  color: ${({ theme, secondary }) => (secondary ? '#c4c4c4' : theme.primaryColor)};
  text-transform: uppercase;
  line-height: 1.75;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: 1rem;
    `}
`;
