import React, { useState, useRef, Fragment, useEffect } from 'react';
import { css } from 'styled-components';
import { DialogOverlay as Overlay, DialogContent as Content } from '@reach/dialog';
/* new */
import cn from 'clsx';
import { useId } from '@reach/auto-id';
import { useLocation } from '@reach/router';

import {
  navCss,
  navListCss,
  firstItemCss,
  primaryItemCss,
  secondaryItemCss,
  mainItemCss,
  itemCss,
  sidebarNavLink,
  sidebarNavHead,
  menuToggleBtn,
  menuToggleBars,
  menuToggleItem,
  menuModalOverlay,
  menuModalContent,
  sidebarNavBody,
} from './SiteNav.styles';
import * as sys from 'src/system';
import { Link } from 'src/app/link';
import { NavDropdown } from 'src/navigation/NavDropdown';
import { RasaLogoAligned } from 'src/navigation/logos';
import { useSiteHeaderNavLinks } from 'src/hooks/use-site-header-nav';
import { Xn } from 'src/abstract/transition';

const badgeCss = css`
  margin-left: 1em;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--brand-color);
  color: white;
  font-size: 87.5%;
`;

function ModalBtnItem({ toggle = () => null, targetId = useId(), isTargetOpen = false, ...rest }) {
  return (
    <li className="mobile-menu-toggle" role="listitem" css={menuToggleItem} {...rest}>
      <button
        type="button"
        className={cn({ isPressed: 'pressed' }, 'mobile-menu-toggle')}
        css={menuToggleBtn}
        aria-expanded={isTargetOpen}
        aria-controls={targetId}
        onClick={toggle}
      >
        <span css={menuToggleBars} />
      </button>
    </li>
  );
}

function ModalMenuPanel({ isOpen, closeFn, children, ...restProps } = {}) {
  const overlayRef = useRef(null);
  const contentRef = useRef(null);
  return (
    <>
      <Xn show={isOpen} name="modal" duration={150} handleMount>
        <Overlay isOpen={true} onDismiss={closeFn} ref={overlayRef} css={menuModalOverlay}>
          <Content ref={contentRef} {...restProps} css={menuModalContent}>
            {children}
          </Content>
        </Overlay>
      </Xn>
    </>
  );
}

export function SiteNav() {
  const location = useLocation();
  const navItems = useSiteHeaderNavLinks();
  const mainItems = navItems.filter(item => !item.cta);
  const [primaryCta] = navItems.filter(item => item.cta === 'primary');
  const [secondaryCta] = navItems.filter(item => item.cta === 'secondary');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const modalId = useId();
  const lastLocationRef = useRef();

  useEffect(() => {
    if (lastLocationRef.current && location !== lastLocationRef.current) {
      closeModal();
    }
    lastLocationRef.current = location;
  }, [location, lastLocationRef]);

  return (
    <nav className="site site-nav" css={[navCss]} aria-label="site navigation">
      <ul role="list" className="site-header-nav" css={[sys.frame, sys.wrapLast, navListCss]}>
        <li role="listitem" className="site-nav-item" css={[firstItemCss, itemCss]}>
          <Link
            href="/"
            css={`
              display: block;
            `}
          >
            <RasaLogoAligned />
          </Link>
        </li>
        {mainItems.map((item) => {
          return (
            <NavDropdown
              className="site-nav-item"
              key={item.text}
              {...item}
              css={[itemCss, mainItemCss]}
              // forceOpen={i == 5}
            />
          );
        })}
        {primaryCta && (
          <NavDropdown
            className="site-nav-item site-nav-btn"
            alignment="right"
            css={[primaryItemCss, itemCss, mainItemCss]}
            {...primaryCta}
            // forceOpen={true}
          />
        )}
        {secondaryCta && (
          <NavDropdown
            className="site-nav-item site-nav-btn"
            alignment="right"
            css={[secondaryItemCss, itemCss, mainItemCss]}
            {...secondaryCta}
            // forceOpen={true}
          />
        )}
        <ModalBtnItem toggle={openModal} targetId={modalId} isTargetOpen={isModalOpen} />
      </ul>
      <ModalMenuPanel isOpen={isModalOpen} closeFn={closeModal}>
        <div css={[sys.frame, sidebarNavHead]}>
          <ul role="list" className="site-header-nav" css={[navListCss]}>
            <li role="listitem" className="site-nav-item" css={[itemCss]}>
              <Link
                href="/"
                css={`
                  display: block;
                `}
              >
                <RasaLogoAligned />
              </Link>
            </li>
            <ModalBtnItem
              toggle={closeModal}
              targetId={modalId}
              isTargetOpen={isModalOpen}
              style={{ marginLeft: 'auto' }}
            />
          </ul>
        </div>
        <ul role="list" css={[sys.frame, sidebarNavBody]}>
          {mainItems.map(({ text, href, items }) => {
            return (
              <li role="listitem" key={text}>
                <p>
                  {href ? (
                    <Link href={href} css={[sidebarNavLink]}>
                      {text}
                    </Link>
                  ) : (
                    text
                  )}
                </p>
                {!!items?.length && (
                  <ul role="list">
                    {items.map(({ text: text2, href: href2, items: subItems, xtra, indent }) => (
                      <li role="listitem" key={text2} style={{ marginLeft: indent ? '1rem' : 'unset' }}>
                        {subItems?.length ? (
                          <Fragment key={text2}>
                            <p className="f-mono">{text2}</p>
                            <ul role="list" key={text2}>
                              {subItems?.map(({ text: text3, href: href3, xtra }) => (
                                <li key={text3}>
                                  <Link href={href3}>
                                    {text3}
                                    {xtra && <span css={[badgeCss]}>{xtra}</span>}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </Fragment>
                        ) : (
                          <Link href={href2}>
                            {text2}
                            {xtra && <span css={[badgeCss]}>{xtra}</span>}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </ModalMenuPanel>
    </nav>
  );
}
