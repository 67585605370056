import { Grid, Column } from './Grid';
import styled from 'styled-components';
import React from 'react';
const Container = styled.section`
  padding: 2rem 1rem 0;
  border-bottom: 1px solid #e3e3e3;
`;
const BorderedGrid = styled(Grid)`
  width: 100%;
  max-width: ${({ theme }) => theme.xlargeContentWidth};
  margin: 0 auto;
`;
const ContentColumn = styled(Column)`
  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    padding: 1.75rem 0;

    &:not(:first-child) {
      border-left: 1px solid #e3e3e3;
      margin-left: -2.5rem;
      padding-left: calc(2.5rem - 1px);
    }
  }
`;
export const ShowcaseGrid = ({ sidebar, children }) => (
  <Container>
    <BorderedGrid columns="1fr 4fr" spacious breakpoint="medium">
      <ContentColumn>{sidebar}</ContentColumn>
      <ContentColumn>{children}</ContentColumn>
    </BorderedGrid>
  </Container>
);
