import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Image } from 'src/containers/Image2';
import { SEO } from 'src/containers/SEO';
import { graphql } from 'gatsby';
import * as React from 'react';
export default {
  Section,
  SectionHeader,
  Image,
  SEO,
  graphql,
  React
};