import { stringify as qs } from 'query-string';

export function imgixSrc(
  url,
  {
    w,
    /**
     * AR
     * allowed: integer ratio as string W:H
     */
    ar,
    /**
     * FIT
     * allowed: clamp | clip | crop | facearea | fill | fillmax | max | min | scale
     * default: clip
     * useful: clip | crop
     */
    fit = ar !== undefined ? 'crop' : undefined,
    /**
     * CROP (only valid if fit == 'crop')
     * allowed: top | bottom | left | right | faces | focalpoint | edges | entropy
     */
    crop = fit === 'crop' ? 'focalpoint' : undefined,
    /**
     * FPX, FPY (only valid if crop == 'focalpoint')
     * allowed: any float from 0 to 1, for each
     */
    fpX = crop === 'focalpoint' ? 0.5 : undefined,
    fpY = crop === 'focalpoint' ? 0.5 : undefined,
    /**
     * FM
     * allowed: gif | jp2 | jpg | json | jxr | pjpg | mp4 | png | png8 | png32 | webm | webp
     * useful: webp | avif (if ever supported)
     */
    fm,
    /**
     * REST
     */
    ...restParams
  } = {},
) {
  const query = qs(
    {
      w,
      ar,
      fit,
      crop,
      'fp-x': fpX,
      'fp-y': fpY,
      fm,
      ...restParams,
    },
    { encode: false },
  );
  const qStart = /\?/.test(url) ? '&' : '?';
  return `${url}${query.length > 0 ? `${qStart}${query}` : ''}`;
}

export function imgixSrcSet(
  url,
  imgixParams = {},
  { minWidth = 240, incWidth = 240, maxWidth = 1920 * 2, debug = false } = {},
) {
  const srcSet = [];
  const baseSrc = imgixSrc(url, imgixParams);
  let w = minWidth;
  while (w <= maxWidth) {
    const srcExtra = `${qs(
      {
        w,
        ...(debug
          ? {
              txt: `${w}w`,
              'txt-align': 'center,middle',
              'txt-font': 'bold',
              'txt-color': 'fff',
              'txt-line-color': '000',
              'txt-shad': 5,
              'txt-line': 2,
              'txt-size': 64,
            }
          : {}),
      },
      { encode: false },
    )}  ${w}w`;
    srcSet.push(`${baseSrc}${baseSrc.match(/\?/) ? '&' : '?'}${srcExtra}`);
    w += incWidth;
  }
  return String(srcSet.reverse());
}

export function parseArString(arString) {
  if (!arString) return false; // allow fallback
  const [n, d] = arString.trim().split(':').map(Number);
  return n / d;
}

export function parseBoolean(bool) {
  return !!bool !== false && String(bool).trim().toLowerCase() !== 'false';
}

/*
  parsing logic
  - fixed or fluid
  - fluid AND constrain
  - if original format is gif, don't generate webp

*/
export function parseToDatoImage(
  {
    url,
    alt,
    title,
    width: nativeWidth,
    // height: nativeHeight,
    format: nativeFormat,
    customData,
    focalPoint,
    resolutions,
    responsiveImage,
    colors,
  } = {},
  {
    // options
    layout = 'fluid', // fluid | fixed | constrain
    lqip = 'blur', // 'blur' | Number (index on colors[])
    width,
    height,
    maxWidth,
    cropFocus,
    aspectRatio,
    constrain,
    format,
  } = {},
) {
  // parse ar value
  const { aspectRatio: nativeAspect, base64 } = resolutions || responsiveImage;
  const useCustomAspect = customData && customData.aspect !== 'Original' && parseBoolean(customData.doCrop);
  const ar =
    aspectRatio ||
    (useCustomAspect ? customData.aspect : width && height ? `${width / height}:1` : undefined);

  // collect imgix parameters
  const imgixParams = {
    ar, // will be undefined if `ar` not resolved
    fpX: ar && focalPoint && focalPoint.x, // will be undefined if `ar` not resolved
    fpY: ar && focalPoint && focalPoint.y, // will be undefined if `ar` not resolved
    crop: cropFocus,
    fm: format !== nativeFormat && format, // will come through only if not the same as native
    auto: format !== nativeFormat && nativeFormat !== 'gif' ? 'format' : undefined,
  };

  // parse out numeric values
  const aspectValue = parseArString(ar) || nativeAspect;

  // parse constrain vs maxWidth
  if (!constrain) constrain = !!maxWidth; // force constrain to true if maxWidth was defined
  if (!maxWidth) maxWidth = nativeWidth; // let maxWidth fall back to nativeWidth

  // sizes: restrict if layout=fixed or constrain=true; otherwise 100vw
  const sizes =
    layout == 'fixed'
      ? `${width}px`
      : constrain
      ? `(min-width: ${maxWidth}px) ${maxWidth}px, 100vw`
      : '100vw';
  const src = imgixSrc(url, { w: layout == 'fixed' ? width : 640, ...imgixParams });
  const srcSet = imgixSrcSet(
    url,
    imgixParams,
    layout == 'fixed'
      ? {
          minWidth: width,
          incWidth: width,
          maxWidth: width * 2,
        }
      : {
          maxWidth: maxWidth * 2,
        },
  );
  // const webpSrc =
  //   nativeFormat !== 'gif' && // don't generate if nativeFormat is 'gif'
  //   imgixSrc(url, { w: layout == 'fixed' ? width : 640, ...imgixParams, fm: 'webp' });
  // const webpSrcSet =
  //   nativeFormat !== 'gif' && // don't generate if nativeFormat is 'gif'
  //   imgixSrcSet(
  //     url,
  //     { ...imgixParams, fm: 'webp' },
  //     layout == 'fixed'
  //       ? {
  //           minWidth: width,
  //           incWidth: width,
  //           maxWidth: Math.min(nativeWidth, width * 2),
  //         }
  //       : {
  //           maxWidth: Math.min(nativeWidth, maxWidth * 2),
  //         },
  //   );

  // format for datocms Image component
  return {
    /*
    alt
    title
    bgColor
    base64
    src
    sizes
    srcSet
    webpSrcSet
    width
    height
    aspectRatio
  */
    alt,
    title,
    src, // if layout == fixed, this is 1x resolution
    srcSet,
    // ...(webpSrc ? { webpSrc } : {}),
    // ...(webpSrcSet ? { webpSrcSet } : {}),
    sizes,
    width: layout == 'fixed' ? width : constrain ? maxWidth : 1,
    height:
      layout == 'fixed'
        ? height || width / aspectValue
        : constrain
        ? maxWidth / aspectValue
        : 1 / aspectValue,
    aspectRatio: nativeAspect,
    ...(lqip == 'blur'
      ? { base64 }
      : {
          bgColor: colors[lqip].hex,
        }),
  };
}
