import React, { forwardRef } from 'react';

import { reduceSystemProps } from 'src/system2';

export const Flow = forwardRef(function Flow(
  {
    as: FlowEl = 'div',
    outerAs: StopEl = 'div',
    sx: { gap, gapY = gap, gapX = gap, ai, ac, ji, jc, ...sx } = {},
    style,
    className,
    ...props
  },
  ref,
) {
  const outerProps = reduceSystemProps({ py: 0.05, w: 'auto', ...sx, style, className });
  const innerProps = reduceSystemProps({ gapY, gapX, ai, ac, ji, jc, className: `flow` });
  return (
    <StopEl {...{ ...outerProps, ref }}>
      <FlowEl {...{ ...innerProps, ...props }} />
    </StopEl>
  );
});

export const FlowGrid = forwardRef(function FlowGrid(
  {
    as: FlowEl = 'div',
    outerAs: StopEl = 'div',
    sx: { gap, gapY = gap, gapX = gap, ai, ac, ji, jc, cols, ...sx } = {},
    style,
    className,
    ...props
  },
  ref,
) {
  const outerProps = reduceSystemProps({ py: 0.05, ...sx, style, className });
  const innerProps = reduceSystemProps({ gapY, gapX, ai, ac, ji, jc, cols, className: 'flow-grid' });
  return (
    <StopEl {...{ ...outerProps, ref }}>
      <FlowEl {...{ ...innerProps, ...props }} />
    </StopEl>
  );
});
