import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Link } from './Link';
/** Removed gatsby-airtable */
// const query = graphql`
//   query EventTeaserQuery {
//     allAirtable(
//       filter: { queryName: { in: ["conferences", "communityEvents"] } }
//       sort: { fields: data___Starting_Date_of_Event }
//     ) {
//       edges {
//         node {
//           data {
//             city: City
//             location: Location
//             name: Name
//             link: Link_to_Event
//             length: Length__Days_
//             date: Starting_Date_of_Event
//           }
//         }
//       }
//     }
//   }
// `;
export const EventTeaser = () => {
  /** Removed gatsby-airtable */
  // const data = useStaticQuery(query);
  // const events = data.allAirtable.edges
  //   .map(({ node: { data } }) => data)
  //   .filter(({ date }) => date >= new Date().toISOString());

  /** Removed gatsby-airtable */
  const events = [];

  if (!events.length) {
    return null;
  }

  return (
    <>
      <h3>More Rasa community events:</h3>
      <ul id="events-list">
        {events.map(({ city, location, name, link, length, date }) => {
          const place = city ?? location;
          const showPlace = !!place;
          const jsDate = new Date(date);
          const jsEndDate = new Date(date);
          let showDateRange = false;

          if (length > 1) {
            showDateRange = true;
            jsEndDate.setDate(jsDate.getDate() + length - 1);
          }

          const showStartMonth = jsDate.getMonth() != jsEndDate.getMonth(); // eg 01 April, 2020

          const formatter = new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }); // Single date

          let formattedDate = formatter.format(jsDate);

          if (showDateRange && showStartMonth) {
            // Date range in separate months
            formattedDate = `${jsDate.toLocaleString('en-GB', {
              day: 'numeric',
              month: 'long',
            })} – ${formatter.format(jsEndDate)}`;
          } else if (showDateRange) {
            // Date range in the same month
            formattedDate = `${jsDate.getDate()} – ${formatter.format(jsEndDate)}`;
          }

          return (
            <li>
              <Link to={link}>{name}</Link>, {formattedDate}, {showPlace && `${place}`}
            </li>
          );
        })}
      </ul>
      <hr />
    </>
  );
};
