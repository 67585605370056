import styled, { css } from 'styled-components';
export const MainTitle = styled.h1`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 2rem;
  }
`;
MainTitle.displayName = 'MainTitle';

export const MainSubtitle = styled.h2`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  font-size: 1.5rem;
  margin: 1.5rem 0;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 1.8rem;
  }
`;
MainTitle.displayName = 'MainTitle';

export const HeaderLink = styled.a`
  display: block;
  font-size: 1.15rem;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 600;
  margin-bottom: 0;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 1rem;
  }
`;

HeaderLink.displayName = 'HeaderLink';

export const MainParagraph = styled.p`
  font-size: 1.15rem;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;
MainParagraph.displayName = 'MainParagraph';
