import { graphql, useStaticQuery } from 'gatsby';
const query = graphql`
  query DynamicCountData {
    site {
      siteMetadata {
        numbers {
          downloads
          forumMembers
          contributors
        }
      }
    }
  }
`;
export const DynamicCount = ({ type }) => {
  const data = useStaticQuery(query);
  return data.site.siteMetadata.numbers[type] || null;
};
