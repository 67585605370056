export function loadScript(src, deferred = true) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.defer = deferred;
    document.body.appendChild(script);
    script.src = src;
  });
}
