import React from 'react';
import styled, { css } from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(47, 17, 92, 0.13);
  background-color: #ffffff;
  margin: 0 0 1.75rem;

  color: ${({ theme }) => theme.baseFontColor};
  --alternative-heading-color: unset;
  --primary-color: ${({ theme }) => theme.primaryColor};
  --secondary-color: white;
`;
const Header = styled.header`
  text-align: center;
  font-size: 2rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.primaryColor};
  color: white;
  font-weight: 500;
  border-radius: 8px 8px 0 0;
`;
const Content = styled.div`
  flex: 1;
  padding: ${({ spacious, noPadding }) => (noPadding ? 0 : spacious ? '3rem 2rem' : '1.5rem')};

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  > *:last-child {
    margin-bottom: 0;
  }
`;
const Footer = styled.footer`
  padding: ${({ spacious, noPadding }) => (noPadding ? 0 : spacious ? '0 3rem 2rem' : '0 1.5rem 1.5rem')};

  > *:last-child {
    margin-bottom: 0;
  }
`;
export const Box = ({ title, centered, spacious, children, footer, noPadding, ...props }) => (
  <Container {...props}>
    {!!title && <Header>{title}</Header>}
    <Content centered={centered} spacious={spacious} noPadding={noPadding}>
      {children}
    </Content>
    {!!footer && <Footer>{footer}</Footer>}
  </Container>
);
