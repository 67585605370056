import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { DeveloperOverview } from 'src/components/organisms/DeveloperOverview';
import get from 'lodash/get';
const query = graphql`
  query DeveloperSectionData {
    site {
      siteMetadata {
        numbers {
          downloads
          forumMembers
          contributors
        }
      }
    }
    memberImages: allFile(
      filter: {
        relativePath: {
          in: [
            "images/profile/1263428.jpeg"
            "images/profile/10572261.jpeg"
            "images/profile/10116000.jpeg"
            "images/profile/3694634.jpeg"
            "images/profile/2381319.jpeg"
            "images/profile/13789524.jpeg"
            "images/profile/1426587.jpeg"
            "images/profile/3694484.png"
            "images/profile/soumya-mukherjee.jpg"
            "images/profile/siddhartha_lahiri.jpg"
            "images/profile/abin_joy.jpg"
            "images/profile/akshay_agarwal.jpg"
            "images/profile/alexis_smirnov.jpg"
            "images/profile/aman_tiwari.jpg"
            "images/profile/andrei_sousa.jpg"
            "images/profile/ankit_sinha.jpg"
            "images/profile/arshid.jpg"
            "images/profile/arthur_temporim.jpg"
            "images/profile/bikash_kumar_sundaray.jpg"
            "images/profile/dmitrii_starov.jpg"
            "images/profile/dominic_breuker.jpg"
            "images/profile/eric_bonfadini.jpg"
            "images/profile/federico_marinelli.jpg"
            "images/profile/fedya_skitsko.jpg"
            "images/profile/feynman_liang.jpg"
            "images/profile/gabriela_barrozo_guedes.jpg"
            "images/profile/greg_stephens.jpg"
            "images/profile/greg_werner.jpg"
            "images/profile/guan_jianyu.jpg"
            "images/profile/guan_wang.jpg"
            "images/profile/jackson_zhipeng_chang.jpg"
            "images/profile/jahir_fiquitiva.jpg"
            "images/profile/jinchao_yu.jpg"
            "images/profile/jonatan_dellagostin.jpg"
            "images/profile/jose_marcos_rodriguez_fernandez_.jpg"
            "images/profile/kevin_castro.jpg"
            "images/profile/luke_jordan.jpg"
            "images/profile/mark_collins.jpg"
            "images/profile/matheus_miranda_lacerda.jpg"
            "images/profile/max_mikhaylov.jpg"
            "images/profile/nahid_alam.jpg"
            "images/profile/nengwe_ntafam_roger.jpg"
            "images/profile/nguyen_dinh_tam.jpg"
            "images/profile/noufal_salim.jpg"
            "images/profile/rahul_prabhakar.jpg"
            "images/profile/rana_mostafa.jpg"
            "images/profile/rania_zyane.jpg"
            "images/profile/ritwik_g.jpg"
            "images/profile/samrudha_kelkar.jpg"
            "images/profile/santiago_gonzalez_toral.jpg"
            "images/profile/shota_makino.jpg"
            "images/profile/sibish_basheer.jpg"
            "images/profile/simon_senkl.jpg"
            "images/profile/sri_yogesh_k.jpg"
            "images/profile/surya_kasturi.jpg"
            "images/profile/ujjwal_puri.jpg"
            "images/profile/utkal_sinha.jpg"
            "images/profile/vishal_jagtap.jpg"
            "images/profile/yifan_zhang.jpg"
          ]
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 80, width: 80)
        }
      }
    }
  }
`;
export const DeveloperSection = ({ hideCta, title, subtitle }) => {
  const data = useStaticQuery(query);
  const { downloads, forumMembers, contributors } = get(data, 'site.siteMetadata.numbers', {});
  return (
    <DeveloperOverview
      title={title}
      subtitle={subtitle}
      downloads={downloads}
      forumMembers={forumMembers}
      contributors={contributors}
      hideCta={hideCta}
      profileImages={data.memberImages.nodes}
    />
  );
};
