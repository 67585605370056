import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import styled, { css } from 'styled-components';

// the following is for fixing the Image2 component
export const Image2 = graphql`
  fragment Image2 on File {
    # relativePath
    publicURL
    extension
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const Image2NoSharp = graphql`
  fragment Image2NoSharp on File {
    publicURL
    extension
  }
`;

const Container = styled.figure`
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 1.75rem')};
  max-width: ${({ width }) => (isNaN(width) ? width : `${width}px`)};

  ${({ height }) =>
    !!height &&
    css`
      min-height: ${isNaN(height) ? height : `${height}px`};
    `}
  ${({ centered }) =>
    centered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
    `}

  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    `}

    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 0.33rem;
    `}


  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    ${({ width, height }) =>
      !!height &&
      css`
        > img {
          max-width: ${isNaN(width) ? width : `${width}px`};
          max-height: ${isNaN(height) ? height : `${height}px`};
        }
      `}
  }
`;
const Caption = styled.figcaption`
  text-align: center;
  font-size: 0.875rem;
  padding-top: 0.5rem;
`;

export const Image = ({
  data,
  caption,
  centered,
  shadow,
  containerProps,
  noMargin,
  inline,
  rounded,
  ...props
}) => {
  const isUnsupported = ['gif', 'svg'].includes(data.extension);
  return (
    <Container
      width={props.width}
      height={props.height}
      centered={centered}
      shadow={shadow}
      noMargin={noMargin}
      inline={inline}
      rounded={rounded}
      {...containerProps}
    >
      {isUnsupported ? ( // @ts-ignore-next-line
        <img loading="lazy" alt="" {...props} src={data.publicURL} />
      ) : (
        <GatsbyImage fluid={data.childImageSharp.fluid} {...props} />
      )}
      {!!caption && <Caption>{caption}</Caption>}
    </Container>
  );
};
