import { graphql, useStaticQuery } from 'gatsby';

export function useSiteFooterNavLinks() {
  const data = useStaticQuery(graphql`
    query {
      allSiteFooterNavYaml {
        nodes {
          items {
            text
            icon
            href
          }
          text
        }
      }
      allOpensourceFooterNavYaml {
        nodes {
          items {
            text
            icon
            href
          }
          text
        }
      }
    }
  `);

  return process.env.GATSBY_WEBSITE_TYPE === 'opensource'
    ? data.allOpensourceFooterNavYaml.nodes
    : data.allSiteFooterNavYaml.nodes;
}
