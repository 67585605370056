import React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { CommunitySection } from './CommunitySection';
import { SectionHeader } from 'src/components/molecules/Section';

function formatCount(count) {
  if (typeof count !== 'number') return count;
  return count.toLocaleString('en-US');
}

const CountHeadline = styled.h2`
  margin: 0;
`;
const CenteredCard = styled(Card)`
  margin: 0;
  font-weight: medium;
  text-align: center;
`;
export const DeveloperOverview = ({
  title = 'Join our fast-growing developer community',
  subtitle = 'Rasa Community is a diverse group of makers and conversational AI enthusiasts.',
  downloads,
  forumMembers,
  contributors,
  hideCta,
  profileImages,
}) => {
  return (
    <CommunitySection profileImages={profileImages}>
      <SectionHeader>
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </SectionHeader>

      <Grid columns="1fr 1fr 1fr">
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(downloads)}+</CountHeadline>
          <strong>Downloads</strong>
        </CenteredCard>
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(forumMembers)}+</CountHeadline>
          <strong>Forum Members</strong>
        </CenteredCard>
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(contributors)}+</CountHeadline>
          <strong>Contributors</strong>
        </CenteredCard>
      </Grid>
      {!hideCta && (
        <>
          <br />
          <InlineList centered>
            <li>
              <Button to="/community/join">Join the Community</Button>
            </li>
            <li>
              <Button
                to="http://forum.rasa.com/?_ga=2.126715716.874322461.1574105054-2119148990.1572962456"
                secondary
              >
                Visit the Forum
              </Button>
            </li>
          </InlineList>
        </>
      )}
    </CommunitySection>
  );
};
