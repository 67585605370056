import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';
const StyledImage = styled(({ centered, ...props }) => <GatsbyImage {...props} />)`
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 1rem;

  ${({ centered }) =>
    centered &&
    css`
      display: block !important;
      margin-left: auto;
      margin-right: auto;
    `}
`;
export const ProfileImage = ({ data, medium, large, centered, ...props }) => {
  if (!data) return null;
  const image = getImage(data);
  return <StyledImage centered={centered} {...props} image={image} />;
};
