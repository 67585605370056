import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';

import { Image } from 'src/containers/Image2';
import { Column, Grid } from 'src/components/molecules/Grid';

const TrailContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    margin: 8rem 0 20rem;
  }
`;
const ImageAnchor = styled.div`
  display: none;
  position: relative;

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    display: block;
  }
`;
const StepContainer = styled.section`
  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    margin-bottom: 9rem;
  }
`;
const StepImage = styled(({ className, ...props }) => (
  <Image
    containerProps={{
      className,
    }}
    {...props}
  />
))`
  width: 100%;
  position: absolute;
  top: 1rem;

  ${({ centered }) =>
    centered
      ? css`
          left: 50%;
          transform: translateX(-50%);
        `
      : css`
          left: 0;
        `}
`;
const BadgeImage = styled(({ className, ...props }) => (
  <Image
    containerProps={{
      className,
    }}
    {...props}
  />
))`
  width: 2.375rem;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  vertical-align: -0.75rem;
`;

export const SaraTrail = () => {
  const data = useStaticQuery(query);
  return (
    <TrailContainer>
      <StepContainer>
        <Grid columns="1fr 1fr">
          <Column>
            <Grid columns="120px 1fr" verticalAlign="center">
              <Column>
                <Image data={data.giftPin} alt="A Rasa pin" width="120" />
              </Column>
              <Column>
                <h3>Rasa Contributor</h3>
                <p>
                  Contributors are community members who help us improve Rasa through code or helping others!
                </p>
              </Column>
            </Grid>
          </Column>
          <Column>
            <ImageAnchor>
              <StepImage data={data.trailSaraFeet_1} role="presentation" width="280" />
            </ImageAnchor>
          </Column>
        </Grid>
      </StepContainer>
      <StepContainer>
        <Grid columns="1fr 2fr" verticalAlign="center">
          <Column />
          <Column>
            <Grid columns="240px 1fr" verticalAlign="center">
              <Column>
                <Image data={data.giftTshirt} alt="A T-shirt with the Rasa logo" width="240" />
              </Column>
              <Column>
                <h3>
                  <BadgeImage data={data.badgeHero} role="presentation" width="38" /> Rasa Hero
                </h3>
                <p>
                  Rasa Heroes are experienced contributors who consistently make high-quality contributions
                  and champion Rasa within other communities!
                </p>
                <ImageAnchor>
                  <StepImage data={data.trailSaraFeet_2} role="presentation" width="228" />
                </ImageAnchor>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </StepContainer>
      <StepContainer>
        <Grid columns="3fr 1fr" verticalAlign="center">
          <Column>
            <Grid columns="300px 1fr" verticalAlign="center">
              <Column>
                <Image data={data.giftSweatshirt} alt="A Rasa Sweatshirt" width="300" />
              </Column>
              <Column>
                <h3>
                  <BadgeImage data={data.badgeSuperhero} role="presentation" width="38" /> Rasa Superhero
                </h3>
                <p>
                  Rasa Superheroes are community leaders who take the Rasa community to the next level in
                  their local area!
                </p>
              </Column>
            </Grid>
          </Column>
          <Column />
        </Grid>
        <ImageAnchor>
          <StepImage centered data={data.trailSaraEnd} role="presentation" width="620" />
        </ImageAnchor>
      </StepContainer>
    </TrailContainer>
  );
};

const query = graphql`
  {
    giftPin: file(relativePath: { eq: "contributors/gift_pin.png" }) {
      ...Image2
    }
    trailSaraFeet_1: file(relativePath: { eq: "contributors/trail_sara_feet_1.svg" }) {
      ...Image2NoSharp
    }
    giftTshirt: file(relativePath: { eq: "contributors/gift_tshirt.png" }) {
      ...Image2
    }
    badgeHero: file(relativePath: { eq: "contributors/badge_hero.png" }) {
      ...Image2
    }
    trailSaraFeet_2: file(relativePath: { eq: "contributors/trail_sara_feet_2.svg" }) {
      ...Image2NoSharp
    }
    giftSweatshirt: file(relativePath: { eq: "contributors/gift_sweatshirt.png" }) {
      ...Image2
    }
    badgeSuperhero: file(relativePath: { eq: "contributors/badge_superhero.png" }) {
      ...Image2
    }
    trailSaraEnd: file(relativePath: { eq: "contributors/trail_sara_end.png" }) {
      ...Image2
    }
  }
`;
