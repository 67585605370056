import React from 'react';
import { css } from 'styled-components';

const absFit = css`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export function RasaLogoAligned({ w = '75px', ...props } = {}) {
  return (
    <div
      css={`
        width: ${w};
      `}
      {...props}
    >
      <div
        css={`
          position: relative;
          height: 0;
          padding-bottom: ${(50 / 99) * 100}%;
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" role="img" aria-labelledby="title desc" viewBox="0 0 99 50" css={absFit}>
          <g>
            <title>Logo of Rasa</title>
            <desc>Logo of Rasa company</desc>
            <path
              fill="var(--fg, currentColor)"
              fillRule="evenodd"
              d="M86 43.39L69.984 35H4V9h91v26h-9v8.39zM0 5v34h69l21 11V39h9V5H0zm8 8h17v8.993l-5.486 2.134L25.13 31h-5.166l-4.372-5.35L12 27.049V31H8V13zm25 18h-4V13h18v18h-4v-6H33v6zm18-7V13h18v4H55v3h14v11H51v-4h14v-3H51zm22-1V13h18v18h-4v-6H77v6h-4v-8zm-61-.244l9-3.5V17h-9v5.756zM33 17v4h10v-4H33zm44 4v-4h10v4H77z"
              clipRule="evenodd"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  );
}

export function RasaLogoSquare({ w = '100%', ...props } = {}) {
  return (
    <div
      css={`
        width: ${w};
      `}
      {...props}
    >
      <div
        css={`
          position: relative;
          height: 0;
          padding-bottom: ${(40 / 34) * 100}%;
        `}
      >
        <div css={absFit}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 40">
            <path
              fill="var(--fg, currentColor)"
              fillRule="evenodd"
              d="M28.23 7.722v-2.54H17.733v10.58h2.519v-3.184h5.457v3.173h2.52v-8.04.01zm-2.52 2.327h-5.457V7.722h5.457v2.327zm-9.656 13.442v4.761H5.768v-2.54h7.767v-1.48H5.768v-6.559h10.286v2.539H8.287v1.48h7.767v1.8zm12.176-3.286v-2.539H17.733v10.58h2.519V25.06h5.457v3.174h2.52v-8.04.01zm-2.52 2.328h-5.457v-2.328h5.457v2.328z"
              clipRule="evenodd"
            ></path>
            <path
              fill="var(--fg, currentColor)"
              d="M30.538 2.861v27.653h-4.351v4.714l-7.868-4.38-.617-.343H3.466V2.861h27.079-.007zm2.736-2.75H.73v33.153h16.268l11.915 6.634v-6.634h4.353V.111h.007z"
            ></path>
            <path
              fill="var(--fg, currentColor)"
              fillRule="evenodd"
              d="M12.677 11.736l3.224-1.183.153-.062V5.19H5.768v.212l-.023 10.367h2.52v-2.414l1.889-.69 2.833 3.104h3.111l-3.421-4.033zm-4.408-1.084V7.728h5.266v.99L8.27 10.652z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}
