import styled, { css, createGlobalStyle } from 'styled-components';

export const navCss = css`
  --site-nav-height: 60px;
  /* --fg: #2c3951; */
  display: flex;
  align-items: stretch;
  height: var(--site-nav-height);
  background-color: var(--header-bg, white);
  font-weight: 500;
  /* background-color: pink; */
  box-shadow: 0 2px 4px 0 var(--header-sh, transparent);
  position: relative;
`;

/* SITE HEADER NAV */

export const navListCss = css`
  @media (min-width: 1000px) {
    --chain-gap: 10px;
  }
  @media (min-width: 1200px) {
    --chain-gap: 30px;
  }
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  > * + * {
    margin-left: var(--chain-gap);
  }
`;

export const firstItemCss = css`
  margin-right: auto;
  --fg: var(--header-ui);
`;

export const primaryItemCss = css`
  margin-left: auto;
  --fg: var(--header-ui);
`;

export const secondaryItemCss = css`
  margin-left: auto;
`;

export const mainItemCss = css`
  display: none;
  @media (min-width: 1000px) {
    display: flex;
  }
`;
export const itemCss = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

/* SITE MODAL NAV */

export const menuToggleItem = css`
  --fg: var(--brand-color);
  height: 100%;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
    display: none;
  }
`;
export const menuToggleBtn = css`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: none;
`;

export const menuToggleBars = css`
  --bar-height: 3px;
  width: 24px;
  height: var(--bar-height);
  position: relative;
  &,
  &::before,
  &::after {
    background-color: var(--header-ui);
    transition-property: background-color, transform;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: var(--bar-height);
    left: 0;
    top: 0;
    transform: translateY(var(--translateY, 0px)) rotate(var(--rotate, 0deg));
  }
  &::before {
    --translateY: calc((var(--bar-height) + 6px) / -1);
  }
  &::after {
    --translateY: calc((var(--bar-height) + 6px) / 1);
  }
  [aria-expanded='true'] > & {
    background: transparent;
    &::before,
    &::after {
      --translateY: 0px;
    }
    &::before {
      --rotate: 45deg;
    }
    &::after {
      --rotate: -45deg;
    }
  }
`;

export const menuModalOverlay = css`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  /* custom */
  user-select: none;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 0%, 0.33);
    transition: opacity var(--xn-duration) linear;
  }
  &.xn-modal-in::before {
    opacity: 1;
  }
  &.xn-modal-out::before {
    opacity: 0;
  }
`;

export const menuModalContent = css`
  user-select: text;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 101;
  outline: none;
  padding: 0;
  /* background: pink; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  transition-property: transform, opacity;
  transition-duration: var(--xn-duration);
  transition-timing-function: ease-in-out;
  /* transition: transform var(--xn-duration) ease-in-out; */
  @media (min-width: 32em) {
    width: 24rem;
    transform: translateX(var(--translateX, 0));
    .xn-modal-in & {
      --translateX: 0%;
    }
    .xn-modal-out & {
      --translateX: 100%;
    }
  }
  transform: scale(var(--scale));
  .xn-modal-in & {
    --scale: 1;
    opacity: 1;
  }
  .xn-modal-out & {
    --scale: 0.9;
    opacity: 0;
  }
`;

export const sidebarNavHead = css`
  height: 60px;
  background: white;
  flex: 0 0 auto;
  --header-ui: var(--brand-color);
  ul {
    list-style: none;
    padding: 0;
  }
`;

export const sidebarNavBody = css`
  overflow-y: auto;
  background: white;
  flex: 1 1 auto;
  color: #2c3951;
  list-style: none;
  a {
    color: #505267;
    &:hover {
      color: var(--brand-color);
    }
  }
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding-top: 1em;
  padding-bottom: 3em;

  > * {
    margin: 0;
  }
  > * + * {
    margin-top: 30px;
  }
  ul {
    font-size: 1rem;
    padding: 0;
    padding-left: 1em;
    list-style: none;
    > * {
      margin: 0;
    }
    > * + * {
      margin-top: 15px;
    }
    li > p {
      color: #6d708b;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 30px;
    }
  }
  li {
    > * {
      margin: 0;
    }
    > * + * {
      margin-top: 15px;
    }
    /* margin: 0; */
    padding: 0;
  }
`;

export const sidebarNavLink = css`
  &:hover {
    text-decoration: underline;
  }
`;
