import React, { useRef, useEffect, useReducer } from 'react';
import { css } from 'styled-components';
import { useId } from '@reach/auto-id';
import { useHover } from '@react-aria/interactions';
import { useLocation } from '@reach/router';

/* new */
import {
  dropMenu as dropMenuCss,
  dropMenuTrigger as dropMenuTriggerCss,
  dropMenuContent as dropMenuContentCss,
  dropMenuPanel as dropMenuPanelCss,
  dropMenuList,
  dropMenuListItem,
  dropSubmenuLabel,
  dropSubmenuList,
  dropMenuLink,
  indentStyles
} from './NavDropdown.styles';
import * as sys from 'src/system';
import { Link } from 'src/app/link';
import { Xn } from 'src/abstract/transition';

const { McolGrid } = sys;

function useElement(selector) {
  const elRef = useRef(null);
  useEffect(() => {
    elRef.current = document.querySelector(selector);
  }, []);
  return elRef.current;
}

const badgeCss = css`
  margin-left: 1em;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--brand-color);
  color: white;
  font-size: 87.5%;
`;

export function NavDropdown({
  text = 'items',
  href,
  items = [],
  forceOpen = false,
  className,
  alignment,
  ...restProps
}) {
  const triggerRef = useRef(null);
  const contentRef = useRef(null);
  const firstLinkRef = useRef(null);
  const [dropdownState, sendDropdownEvent] = useReducer(
    (state, event) =>
      ({
        open: {
          hoverEnd: 'closed',
          escapeKey: 'closed',
          focusOut: 'closed',
          clickOutside: 'closed',
          locationChange: 'closed',
        },
        closed: {
          hoverStart: 'open',
          triggerClick: 'open',
        },
      }[state][event] || state),
    'closed',
  );
  const { hoverProps, isHovered } = useHover({
    onHoverStart: () => sendDropdownEvent('hoverStart'),
    onHoverEnd: () => sendDropdownEvent('hoverEnd'),
  });

  // track the location, and reset focus to top
  const location = useLocation();
  const focusWrapper = useElement('#gatsby-focus-wrapper');
  useEffect(() => {
    if (dropdownState == 'open') {
      document.activeElement.blur();
      setTimeout(() => {
        sendDropdownEvent('locationChange');
        focusWrapper.focus();
      }, 0);
    }
  }, [location]);

  // handle blurring out of content area
  const onBlurCapture = ({ relatedTarget }) => {
    if (relatedTarget && !contentRef.current.contains(relatedTarget)) {
      setTimeout(() => {
        sendDropdownEvent('focusOut');
      }, 0);
    }
  };

  const onKeyUpCapture = (event) => {
    if (event.key !== 'Escape') return;
    setTimeout(() => {
      sendDropdownEvent('escapeKey');
      if (triggerRef.current) triggerRef.current.focus();
    }, 0);
  };

  const onClickOutside = (event) => {
    if (
      event.target &&
      [contentRef, triggerRef].some((ref) => ref.current && ref.current.contains(event.target))
    )
      return;
    event.preventDefault();
    setTimeout(() => {
      sendDropdownEvent('clickOutside');
      if (triggerRef.current) triggerRef.current.focus();
    }, 0);
  };

  useEffect(() => {
    if (dropdownState == 'open') {
      document.addEventListener('mousedown', onClickOutside);
    } else {
      document.removeEventListener('mousedown', onClickOutside);
    }
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, [dropdownState]);

  const triggerID = useId();
  const contentID = useId();
  const hasChildren = !!items?.length;
  const isMultiList = items?.some((item) => 'items' in item && item.items != null);

  if (!hasChildren) {
    return (
      <li
        role="listitem"
        className={`drop-menu ${className}`}
        onBlurCapture={onBlurCapture}
        onKeyUpCapture={onKeyUpCapture}
        {...hoverProps}
        {...restProps}
        css={dropMenuCss}
      >
        <Link
          href={href}
          id={triggerID}
          ref={triggerRef}
          className={`drop-menu-trigger ${isHovered ? 'hover' : ''}`}
          css={[sys.btn, dropMenuTriggerCss]}
        >
          <span className="target">{text}</span>
        </Link>
      </li>
    );
  }

  return (
    <li
      role="listitem"
      className={`drop-menu ${className}`}
      onBlurCapture={onBlurCapture}
      onKeyUpCapture={onKeyUpCapture}
      {...hoverProps}
      {...restProps}
      css={dropMenuCss}
    >
      <button
        type="button"
        id={triggerID}
        ref={triggerRef}
        className={`drop-menu-trigger ${isHovered ? 'hover' : ''}`}
        aria-haspopup="true"
        aria-controls={contentID}
        aria-expanded={dropdownState === 'open' || forceOpen}
        onClick={() => sendDropdownEvent('triggerClick')}
        css={[sys.btn, dropMenuTriggerCss]}
      >
        <span className="target">{text}</span>
      </button>
      <Xn show={dropdownState === 'open' || forceOpen} name="nav-dropdown" duration={100}>
        {(show) => {
          return (
            <div
              className={`drop-menu-content ${alignment ? `align-${alignment}` : ''}`}
              id={contentID}
              ref={contentRef}
              aria-hidden={show}
              css={dropMenuContentCss}
              style={{ visibility: show ? 'visible' : 'hidden' }}
            >
              {isMultiList ? (
                <div className="drop-menu-panel" css={dropMenuPanelCss}>
                  <McolGrid
                    as="ul"
                    role="list"
                    aria-labelledby={triggerID}
                    css={dropMenuList}
                    style={{ '--multi-columns': '2' }}
                  >
                    {items?.map(({ text, items }, i) => {
                      const labelID = useId();
                      return (
                        <li role="listitem" key={text}>
                          <p
                            id={labelID}
                            className="drop-submenu-name f-mono"
                            style={{ '--font-size': '12px' }}
                            css={dropSubmenuLabel}
                          >
                            {text}
                          </p>
                          {items?.length && (
                            <ul role="list" aria-labelledby={labelID} css={[dropMenuList, dropSubmenuList]}>
                              {items.map(({ text, href, xtra }, j) => (
                                <li role="listitem" key={text} css={dropMenuListItem}>
                                  <Link
                                    className="drop-menu-link"
                                    css={dropMenuLink}
                                    ref={i === 0 && j === 0 ? firstLinkRef : undefined}
                                    href={href}
                                  >
                                    {text}
                                    {xtra && <span css={[badgeCss]}>{xtra}</span>}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </McolGrid>
                </div>
              ) : (
                <div className="drop-menu-panel" css={dropMenuPanelCss}>
                  <ul role="list" css={dropMenuList}>
                    {items?.map(({ text, href, xtra, indent }, i) => (
                      <li role="listitem" key={text} css={[
                        dropMenuListItem,
                        ...(indent ? [indentStyles] : [])
                      ]}>
                        <Link
                          className="drop-menu-link"
                          css={dropMenuLink}
                          ref={i === 0 ? firstLinkRef : undefined}
                          href={href}
                        >
                          {text}
                          {xtra && <span css={[badgeCss]}>{xtra}</span>}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
        }}
      </Xn>
    </li>
  );
}
