import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngellist,
  faGithub,
  faStackOverflow,
  faLinkedin,
  faTwitter,
  faYoutube,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faBuilding,
  faCode,
  faGraduationCap,
  faLightbulbOn,
  faMedal,
  faPencil,
  faPodium,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowRight,
  faBadgeCheck,
  faBars,
  faBriefcase,
  faCalendar,
  faCaretRight,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faCodeMerge,
  faCogs,
  faCoins,
  faComment,
  faCommentAltSmile,
  faConciergeBell,
  faDumbbell,
  faExternalLink,
  faFileCertificate,
  faFileInvoiceDollar,
  faFileSearch,
  faFileUser,
  faHeadset,
  faHome,
  faHospital,
  faLifeRing,
  faMobile,
  faMortarPestle,
  faPhone,
  faPhoneLaptop,
  faPiggyBank,
  faPlane,
  faQuestionCircle,
  faRepeat,
  faShoppingCart,
  faSpeaker,
  faUmbrella,
  faUserMd,
  faVideo,
  faUsersClass,
  faBookOpen,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAnalytics,
  faChartNetwork,
  faCircle,
  faComments,
  faCommentsAlt,
  faKey,
  faLifeRing as fasLifeRing,
  faUserLock,
  faCaretDown,
  faCheckCircle as fasCheckCircle,
  faArrowLeft as fasArrowLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';
library.add(
  faTwitter,
  faGithub,
  faAngellist,
  faLinkedin,
  faStackOverflow,
  faYoutube,
  faUmbrella,
  faHospital,
  faUserMd,
  faMortarPestle,
  faDumbbell,
  faCheckCircle,
  faCheck,
  faBars,
  faLifeRing,
  faConciergeBell,
  faExternalLink,
  faRepeat,
  faCogs,
  faChalkboardTeacher,
  faComment,
  faChevronDown,
  faCode,
  faUsers,
  faPencil,
  faPodium,
  faGraduationCap,
  faLightbulbOn,
  faCaretRight,
  faCalendar,
  faHeadset,
  faFileSearch,
  faCommentAltSmile,
  faCodeMerge,
  faFileUser,
  faPlane,
  faShoppingCart,
  faPhoneLaptop,
  faPhone,
  faBriefcase,
  faPiggyBank,
  faBadgeCheck,
  faFileInvoiceDollar,
  faCoins,
  faSpeaker,
  faHome,
  faMobile,
  faQuestionCircle,
  faKey,
  faAnalytics,
  faCommentsAlt,
  faUserLock,
  fasLifeRing,
  faChartNetwork,
  faMedal,
  faBuilding,
  faComments,
  faVideo,
  faFileCertificate,
  faArrowRight,
  fasArrowLeft,
  faCircle,
  faTimes,
  faCaretDown,
  faUsersClass,
  faBookOpen,
  fasCheckCircle,
  faXTwitter
); // @ts-ignore-next-line

export const availableIcons = Object.entries(library.definitions).reduce((icons, [set, iconObj]) => {
  const setIcons = Object.keys(iconObj).reduce((icons, icon) => ({ ...icons, [icon]: [set, icon] }), {});
  return { ...icons, ...setIcons };
}, {});
const StyledIcon = styled(({ center, circle, ...props }) => <FontAwesomeIcon {...props} />)`
  ${({ center }) =>
    center &&
    css`
      display: block !important;
      margin: 0 auto;
    `}

  ${({ circle }) =>
    circle &&
    css`
      padding: 1.5rem;
      background-color: #6c5ef9;
      border-radius: 100%;
      box-sizing: content-box;
    `}
`;
export const Icon = ({ icon, center, circle, ...props }) => {
  const iconLookup = typeof icon === 'string' ? ['fal', icon] : icon;
  return <StyledIcon icon={iconLookup} center={center} circle={circle} {...props} />;
};
