import React from 'react';

import { TeaserList, TeaserListItem } from 'src/components/molecules/TeaserList';
import { FixedImg } from 'src/embeds/image/image-dato';
import { useBlogLast3 } from 'src/hooks';

export const DocsBlogPosts = () => {
  const data = useBlogLast3();
  return (
    <TeaserList action="/blog/" actionLabel="Read more on the Rasa blog">
      {data.allDatoCmsPost.nodes.map((node) => {
        const primaryAuthor = node.authors[0];
        return (
          <TeaserListItem
            key={node.slug} //image={<GatsbyImage fixed={node.featureImageFile.childImageSharp.fixed} />}
            image={
              node.featureImage ? <FixedImg width={100} height={56} data={node.featureImage} /> : undefined
            }
            to={`/blog/${node.slug}`}
          >
            <div>
              <h4>{node.title}</h4>
              By {primaryAuthor.name}, {node.meta.firstPublishedAt}
            </div>
          </TeaserListItem>
        );
      })}
    </TeaserList>
  );
};
