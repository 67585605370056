import React from 'react';
import ReactPlayer from 'react-player/lazy';
import styled, { css } from 'styled-components';
// TODO: avoid spreading props in to HTML div, use SC 5.1 API to prevent leakage
const Container = styled(({ width, height, shadow, round, ...props }) => <div {...props} />)`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: ${({ width = '100%' }) => (isNaN(width) ? width : `${width}px`)};
  height: auto;
  max-width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  ${({ shadow }) =>
    !!shadow &&
    css`
      box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
    `}

  ${({ round }) =>
    !!round &&
    css`
      border-radius: 8px;
    `}

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: ${({ height = '56.25%' }) => height};
  }
`;
const ContentVideo = styled(ReactPlayer)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  object-fit: contain;
  object-position: center top;
`;

function parseYoutube(url) {
  if (!url) return null;
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : null;
}

export const Video = ({
  className,
  src,
  width,
  height,
  shadow,
  youtube,
  youtubeStart,
  youtubeEnd,
  autoPlay,
  controls,
  round,
  ...props
}) => {
  const youtubeId = youtube || parseYoutube(src);
  return (
    <Container width={width} height={height} className={className} shadow={shadow} round={round}>
      {youtubeId ? (
        <ContentVideo
          url={`https://www.youtube.com/embed/${youtubeId}`}
          config={{
            youtube: {
              playerVars: {
                start: youtubeStart,
                end: youtubeEnd,
              },
            },
          }}
          controls={controls}
          autoPlay={autoPlay}
          width="100%"
          height="100%"
          light // Do not preload youtube script
          onClickPreview={(e) => {
            e.preventDefault();
          }}
          {...props}
        />
      ) : (
        <ContentVideo
          url={src}
          controls={controls}
          autoPlay={autoPlay}
          width="100%"
          height="100%"
          {...props}
        />
      )}
    </Container>
  );
};
