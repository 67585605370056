import React, { useMemo, useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button } from 'src/components/atoms/Button';
import { Banner } from 'src/components/molecules/Banner';

const query = graphql`
  query AnnouncementBarData {
    datoCmsAnnouncementBar {
      id
      text
      display
      ctaText
      ctaUrl
      countdownDate
      countdownDisplay
    }
  }
`;

export const AnnouncementBanner = () => {
  const [count, setCount] = useState(0);
  const data = useStaticQuery(query);

  if (!data.datoCmsAnnouncementBar.display) {
    return null;
  }

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);

    // Clearing the interval
    return () => clearInterval(interval);
  }, [count]);

  const countDownDate = useMemo(() => {
    if (!data.datoCmsAnnouncementBar.countdownDate || !data.datoCmsAnnouncementBar.countdownDisplay) {
      return null;
    }

    // Get today's date and time
    const countDownDate = new Date(data.datoCmsAnnouncementBar.countdownDate).getTime();
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  }, [
    data.datoCmsAnnouncementBar.countdownDate,
    data.datoCmsAnnouncementBar.countdownDisplay,
    count
  ])

  return (
    <Banner
      centered
      primary
      style={{
        backgroundColor: '#0a0d2a',
      }}
    >
      {countDownDate && <span style={{
        color: '#E42EBB',
        fontWeight: 500
      }}>
        {countDownDate}
      </span>}
      {data.datoCmsAnnouncementBar.text}
      {data.datoCmsAnnouncementBar.ctaText
        && data.datoCmsAnnouncementBar.ctaUrl
        && (
        <span
          css={{
            marginLeft: '1em',
          }}
        >
          <Button
            to={data.datoCmsAnnouncementBar.ctaUrl}
            target="_blank"
            secondary
            tiny
            style={{
              color: 'cyan',
              borderColor: 'cyan',
            }}
          >
            {data.datoCmsAnnouncementBar.ctaText}
          </Button>
        </span>
      )}
    </Banner>
  );
};
