import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'src/containers/Link';
const Icon = styled.span`
  margin-right: 0.5rem;
`;

const RawButton = ({
  to,
  icon,
  secondary,
  tertiary,
  children,
  type,
  tiny,
  small,
  large,
  noMargin,
  ...props
}) => {
  return type === 'button' || type === 'submit' || !to ? (
    <button {...props}>
      {icon && <Icon>{icon}</Icon>}
      {children}
    </button>
  ) : (
    <Link to={to} {...props}>
      {icon && <Icon>{icon}</Icon>}
      {children}
    </Link>
  );
};

export const buttonStyles = css`
  display: ${({ expanded }) => (expanded ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color, ${({ theme }) => theme.primaryColor});
  border-radius: 8px;
  border: ${({ tiny }) => (tiny ? '1px' : '2px')} solid
    var(--primary-color, ${({ theme }) => theme.primaryColor});
  padding: ${({ small, tiny, large }) =>
    large ? '0.5rem 1rem' : small ? '0.4375rem 1rem' : tiny ? '0.28rem 0.75rem' : '0.5625rem 1.375rem'};
  font-size: ${({ theme, large }) => (large ? theme.largeFontSize : theme.smallFontSize)};
  font-weight: 500;
  line-height: inherit;
  color: var(--secondary-color, white);
  text-decoration: none;
  vertical-align: baseline;
  cursor: pointer;
  ${({ noMargin }) => !noMargin && `margin-bottom: 1rem;`}
  transition: all 0.1s ease;
  -webkit-font-smoothing: auto;

  &:hover,
  &:active {
    opacity: 0.9;
    ${({ tertiary }) =>
      !tertiary &&
      css`
        text-decoration: none;
        box-shadow: 0 2px 4px 0 rgba(109, 112, 139, 0.2);
      `}
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: transparent;
      color: inherit;
      border-color: #ccc;
    `}

  ${({ tertiary }) =>
    tertiary &&
    css`
      background-color: transparent;
      border-color: transparent;
      color: var(--primary-color, ${({ theme }) => theme.primaryColor});
    `}
`;
export const Button = styled(RawButton)`
  ${buttonStyles}
`;
Button.displayName = 'Button';
