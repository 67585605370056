// global styles
import './styles/index.scss';

// primitives
export { Box } from './box';
export { Stack, Chain } from './stack';
export { Grid } from './grid';
export { Flow, FlowGrid } from './flow';

import { isObject } from '../utils/class-of';
import { breakpoints } from '../../tabula.config';

// prettier-ignore
const allSystemKeys = [
  'm', 'mt', 'mr', 'mb', 'ml', 'my', 'mx',
  'p', 'pt', 'pr', 'pb', 'pl', 'py', 'px',
  'w', 'h', 'maxW', 'maxH', 'minW', 'minH', 'aspect',
  'fz', 'fw',
  // 'fg', 'bg', 'bd', 'ol',
  'gap', 'gapY', 'gapX',
  'cols', 'colMinW', 'colMaxW', // grid / flow / mcol parent props
  'span', 'start', 'end', // grid child props
  'rows', 'rowSpan', 'rowStart', 'rowEnd', // grid child props
  'push', 'pull', 'pushR', 'pullR', // flow-grid child props, WIP
  // utility
  'd', 'ai', 'ji', 'ac', 'jc', 'ta', 'va', 'as', 'js', 'z', 'order',
  // utility special
  // pos, flex, objPos, objFit,
];

// these are the only keys for which we parse units or keywords
// prettier-ignore
const dimensionalSystemKeys = [
  'm', 'mt', 'mr', 'mb', 'ml', 'my', 'mx',
  'p', 'pt', 'pr', 'pb', 'pl', 'py', 'px',
  'gap', 'gapY', 'gapX',
  'w', 'h', 'maxW', 'maxH', 'minW', 'minH',
  'fz',
  'colMinW', 'colMaxW',
]

const spacingSystemKeys = [
  'mt',
  'mr',
  'mb',
  'ml',
  'my',
  'mx',
  'pt',
  'pr',
  'pb',
  'pl',
  'py',
  'px',
  'gap',
  'gapY',
  'gapX',
];

// TODO: review keyword values and outputValue response to string dimensions
const keywordValues = ['unset', 'initial', 'inherit', 'auto'];

const getOuterSpacingVar = (key, val) => {
  return ~spacingSystemKeys.indexOf(key)
    ? {
        t: 'var(--outer-top)',
        r: 'var(--outer-right)',
        b: 'var(--outer-bottom)',
        l: 'var(--outer-left)',
      }[key.slice(key.length - 1).toLowerCase()] || val
    : val;
};

const getInnerSpacingVar = (key, val) => {
  return ~spacingSystemKeys.indexOf(key)
    ? {
        t: `var(--inner-y-${val})`,
        r: `var(--inner-x-${val})`,
        b: `var(--inner-y-${val})`,
        l: `var(--inner-x-${val})`,
        y: `var(--inner-y-${val})`,
        x: `var(--inner-x-${val})`,
      }[key.slice(key.length - 1).toLowerCase()] || val
    : val;
};

function outputValue(key, val) {
  if (val === null) return 'unset'; // NOTE: not sure about this
  val = typeof val === 'string' ? val.trim() : val;
  if (~keywordValues.indexOf(val)) return val;
  if (!~dimensionalSystemKeys.indexOf(key)) return val;
  const number = parseFloat(val);
  if (Number.isNaN(number)) {
    if (!~spacingSystemKeys.indexOf(key)) return val;
    if (val == 'frame') return getOuterSpacingVar(key, val);
    if (val == 'bleed') return `calc(${getOuterSpacingVar(key, val)}/-1)`;
    return val.startsWith('neg-')
      ? `calc(${getInnerSpacingVar(key, val.slice(4))}/-1)`
      : getInnerSpacingVar(key, val);
  }
  const unit = String(val).replace(number, '');
  return `${number}${unit || 'px'}`;
}

// prettier-ignore
const normalizeSystemProps = ({
  // NOTE: this does not extract ALL system props, only those that need remapping
  m,
  my=m, mt=my, mb=my,
  mx=m, mr=mx, ml=mx,
  p,
  py=p, pt=py, pb=py,
  px=p, pr=px, pl=px,
  sz, w=sz, h=sz,
  gap, gapY=gap, gapX=gap,
  cols, rows,
  span, rowSpan,
  start, end,
  rowStart, rowEnd,
  push, pull, pushR, pullR,
  ...rest
} = {}) => ({
  gapY: setPropFallback(gapY, 'default'),
  gapX: setPropFallback(gapX, 'default'),
  cols: setPropFallback(cols, 1),
  rows: setPropFallback(rows, 1),
  span: setPropFallback(span, 1),
  rowSpan: setPropFallback(rowSpan, 1),
  // NOTE: (flex-)grid cell positioning overrides margin and dimensions
  mr: pushR||pullR||start||end ? undefined : mr,
  ml: push||pull||start||end ? undefined : ml,
  mt: rowStart||rowEnd ? undefined : mt,
  mb: rowStart||rowEnd ? undefined : mb,
  w: span||start||end ? undefined : w,
  h: rowSpan||rowStart||rowEnd ? undefined : h,
  pr, pl,
  pt, pb,
  start, end,
  rowStart, rowEnd,
  push, pull, pushR, pullR,
  ...rest
});

const defaultPropVarMap = {
  colMaxW: 'col-max-w',
  colMinW: 'col-min-w',
  rowSpan: 'row-span',
  rowStart: 'row-start',
  rowEnd: 'row-end',
  gapY: 'gap-y',
  gapX: 'gap-x',
  maxW: 'max-w',
  minW: 'min-w',
  maxH: 'max-h',
  minH: 'min-h',
};

const defaultPropClassMap = {};

export function setPropFallback(val, _ = 'unset') {
  return val !== undefined ? (isObject(val) ? Object.assign({ _ }, val) : val) : val;
}

/*
  TODOs

  - force sorting of breakpoint keys at the step where they are parsed out
  - do something special with `revert` as a value
    - see if the propkey has a special revert value
    - otherwise output the string 'revert' (which has limited browser support)
  - consider outputting state-base classes instead of definining a fallback
    - e.g. `s__cols` for cols etc.
    - the invisibility of the fallback mechanism is confusing
    - this would avoid potential conflict with written CSS
  - remove the `propClassMap` option
    - keep the logic simpler and compensate inside the base-classes instead
  - remove scheme keys
    - they generally don't need to be responsive
    - the multiple CP sub-declarations won't come along, e.g. --is__bg-dark
 */

export function reduceSystemProps(
  { style = {}, className = '', ...restProps } = {},
  { propVarMap, propClassMap } = {},
) {
  style = { ...style }; // avoid mutation
  propVarMap = Object.assign({}, defaultPropVarMap, propVarMap || {});
  propClassMap = Object.assign({}, defaultPropClassMap, propClassMap || {}); // tentative removal
  const parsedProps = normalizeSystemProps(restProps),
    classList = className.split(/\s+/).filter(Boolean),
    nonSystemProps = {};
  for (const key in parsedProps) {
    const propValue = parsedProps[key];
    if (propValue === undefined) continue;
    if (~allSystemKeys.indexOf(key)) {
      const varString = key in propVarMap ? propVarMap[key] : key;
      const classString = key in propClassMap ? propClassMap[key] : varString; // tentative removal
      if (!isObject(propValue)) {
        classList.push(classString);
        style[`--${varString}`] = outputValue(key, propValue);
      } else {
        const breakpoints = Object.keys(propValue);
        const bp0 = breakpoints[0]; // TODO: force sorting of breakpoints here
        classList.push(classString ? (bp0 == '_' ? classString : `${bp0}__${classString}`) : '');
        let styleValue = bp0 == '_' ? `${outputValue(key, propValue[bp0])}` : '';
        for (const bp of breakpoints) {
          if (bp !== '_') {
            style[`--${bp}__${varString}`] = `var(--is__${bp}) ${outputValue(key, propValue[bp])}`;
          }
          styleValue =
            bp == '_'
              ? styleValue
              : styleValue.length
              ? `var(--${bp}__${varString}, ${styleValue})`
              : `var(--${bp}__${varString})`;
        }
        style[`--${varString}`] = `${styleValue}`;
      }
    } else {
      nonSystemProps[key] = parsedProps[key];
    }
  }
  return {
    className: classList.filter(Boolean).join(' ').trim() || undefined,
    style: style && Object.keys(style).length > 0 ? style : undefined,
    ...nonSystemProps,
  };
}

export function queryString(query) {
  const [lo, hi] = [].concat(query).filter(Boolean);
  const loValue = lo && parseInt(breakpoints[lo]);
  const hiValue = hi && parseInt(breakpoints[hi]);
  const loQuery = loValue ? ` and (min-width: ${loValue}em)` : '';
  const hiQuery = hiValue ? ` and (max-width: ${hiValue - 0.01}em)` : '';
  return loQuery || hiQuery ? `@media screen${loQuery}${hiQuery}` : '';
}

export const media = (query, expr) => {
  const mediaQuery = queryString(query);
  return [`${mediaQuery} {`, expr, `}`];
};
