import React from 'react';
/* new */

import { PageHeader } from 'src/layouts/PageHeader';
import styled, { css } from 'styled-components';
const Container = styled.section`
  position: relative;
  padding: 0 1rem 2rem;

  ${({ customTextColor }) =>
    customTextColor &&
    css`
      color: ${customTextColor};
      --alternative-heading-color: ${customTextColor};
      --primary-color: ${customTextColor};
    `}

  ${({ alternative, primary }) =>
    (alternative || primary) &&
    css`
      color: white;
      --alternative-heading-color: white;
      --primary-color: white;
      --secondary-color: #343434;
    `}

  ${({ sloped }) =>
    sloped &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin-bottom: -16rem;
        padding-bottom: 16rem;
      }
    `}
`;
const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: -5.5rem;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 60rem;
  width: 100%;

  ${({ alternative, image, theme, primary, dark }) =>
    (alternative || primary || dark) &&
    css`
      background-color: ${dark ? '#2c3951' : theme.primaryColor};

      ${alternative &&
      !!image &&
      css`
        height: calc(100% - 10rem);
      `}
    `};

  ${({ sloped }) =>
    sloped &&
    css`
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), 0 100%);

      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16rem), 0 100%);
      }
    `}

  ${({ wavey }) =>
    wavey &&
    css`
      bottom: 3.125rem;
    `}
`;
const BackgroundWave = styled.svg`
  height: 3.125rem;
  width: 100%;
  position: absolute;
  bottom: -3.125rem;
  left: 0;
  right: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0 0;
  text-align: center;
  margin: 0 auto;
  max-width: ${({ width, theme }) =>
    width === 'xlarge' ? theme.xlargeContentWidth : theme.largeContentWidth};

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    flex-direction: row;
    align-items: center;

    ${({ alternative, centered }) =>
      alternative
        ? css`
            flex-direction: column;
            text-align: center;
          `
        : css`
            text-align: ${centered ? 'center' : 'left'};
          `}
  }
`;
const Content = styled.div`
  flex: 1.1;
  margin-bottom: 2rem;

  h5 {
    color: inherit;
  }
`;
const HeroImageContainer = styled.div`
  position: relative;
  text-align: center;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    margin-left: 2rem;
  }

  ${({ alternative }) =>
    alternative &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    `}
  ${({ wavey }) =>
    wavey &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin-bottom: -2rem;
      }
    `};
`;
const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
const SubtitleContainer = styled.div`
  hyphens: none;
  max-width: ${({ theme, subtitleWidth }) => {
    switch (subtitleWidth) {
      case 'small':
        return theme.smallContentWidth;

      case 'medium':
        return theme.mediumContentWidth;

      case 'large':
        return theme.largeContentWidth;

      case 'xlarge':
        return theme.xlargeContentWidth;

      case 'full':
        return '100%';

      default:
        return theme.largeContentWidth;
    }
  }};
  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto;
    `}
`;
export const Hero = ({
  width = 'large',
  centered,
  title,
  subtitle,
  category,
  actions,
  image,
  alternative,
  backgroundImage,
  sloped,
  wavey,
  primary,
  dark,
  subtitleWidth = 'small',
  brandColor,
  headerColor,
  footerBackgroundColor,
  footerTextColor,
  footerHeaderColor,
  customTextColor
}) => {
  return (
    <PageHeader
      {...{
        alternative,
        primary,
        dark,
        brandColor,
        headerColor,
        footerBackgroundColor,
        footerTextColor,
        footerHeaderColor
      }}
    >
      <Container
        customTextColor={customTextColor}
        alternative={alternative}
        sloped={sloped}
        primary={primary}
      >
        <BackgroundContainer>
          <Background
            alternative={alternative}
            image={image}
            sloped={sloped}
            wavey={wavey}
            primary={primary}
            dark={dark}
          >
            {wavey && (
              <BackgroundWave
                viewBox="0 0 500 50"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <path
                  d="M0,-0l500,-0l0,50c-77,-44.862 -160,-50.52 -249.5,-17.756c-89.5,21.675 -173,21.675 -250.5,-13.609"
                  fill="var(--header-bg)"
                />
              </BackgroundWave>
            )}
            {!!backgroundImage && <BackgroundImageContainer>{backgroundImage}</BackgroundImageContainer>}
          </Background>
        </BackgroundContainer>

        <Wrapper centered={centered && !image} width={width} alternative={alternative} image={image}>
          <Content>
            {!!category && <h5>{category}</h5>}
            <h1>{title}</h1>
            <SubtitleContainer centered={centered} subtitleWidth={subtitleWidth}>
              {!!subtitle && (typeof subtitle === 'string' ? <p>{subtitle}</p> : subtitle)}
              {actions}
            </SubtitleContainer>
          </Content>
          {!!image && (
            <HeroImageContainer alternative={alternative} wavey={wavey}>
              {image}
            </HeroImageContainer>
          )}
        </Wrapper>
      </Container>
    </PageHeader>
  );
};
