import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/components/atoms/Button';
const DismissButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  border: 0;
  padding: 0.7rem 1rem;
  background-color: transparent;
  color: inherit;
  font-size: 1.5rem;
  cursor: pointer;
`;
const Container = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 99;

  padding: ${({ large }) => (large ? '1.5rem 3rem' : '0.6rem 3rem')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  font-size: ${({ small }) => (small ? '.875rem' : '1rem')};

  background-color: ${({ primary, secondary, backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : primary ? theme.primaryColor : secondary ? '#f3f4fa' : 'white'};

  ${({ primary }) =>
    primary &&
    css`
      --primary-color: white;
      --secondary-color: #343434;
      color: white;
    `}

  > *:last-child {
    margin-bottom: 0;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    `}

  ${Button} {
    margin: 0;
  }
`;
export const Banner = ({ children, onDismiss, backgroundImage, ...props }) => (
  <Container {...props}>
    {backgroundImage}
    {children}
    {!!onDismiss && (
      <DismissButton
        onClick={(e) => {
          e.preventDefault();
          onDismiss && onDismiss();
        }}
      >
        ✕
      </DismissButton>
    )}
  </Container>
);
Banner.displayName = 'Banner';
