// TRICK: https://ultimatecourses.com/blog/understanding-javascript-types-and-reliable-type-checking#true-object-types
const classof = (value) => Object.prototype.toString.call(value).slice(8, -1);
export const isString = (value) => classof(value) === 'String';
export const isDate = (value) => classof(value) === 'Date';
export const isRegExp = (value) => classof(value) === 'RegExp';
export const isNumber = (value) => classof(value) === 'Number' && !Number.isNaN(value);
export const isNaN = (value) => Number.isNaN(value);
export const isInteger = (value) => Number.isInteger(value);
export const isBoolean = (value) => classof(value) === 'Boolean';
export const isNull = (value) => classof(value) === 'Null';
export const isUndefined = (value) => classof(value) === 'Undefined';
export const isFunction = (value) => classof(value) === 'Function';
export const isArray = (value) => classof(value) === 'Array';
export const isObject = (value) => classof(value) === 'Object';
export const isSymbol = (value) => classof(value) === 'Symbol';
export const isMap = (value) => classof(value) === 'Map';
export const isSet = (value) => classof(value) === 'Set';
export const isWeakMap = (value) => classof(value) === 'WeakMap';
export const isWeakSet = (value) => classof(value) === 'WeakSet';

// from https://stackoverflow.com/questions/10776600/testing-for-equality-of-regular-expressions
const areEqualRegexes = (x, y) => {
  return (
    x instanceof RegExp &&
    y instanceof RegExp &&
    x.source === y.source &&
    x.global === y.global &&
    x.ignoreCase === y.ignoreCase &&
    x.multiline === y.multiline
  );
};

// from https://www.gatsbyjs.com/docs/how-to/custom-configuration/add-custom-webpack-config/#modifying-the-babel-loader
const areEqualRegexesB = (re1, re2) => String(re1) == String(re2);
