import React from 'react';
import get from 'lodash/get';
import { MDXProvider } from '@mdx-js/react';

import { Button } from 'src/components/atoms/Button';
import { Hero } from 'src/components/organisms/Hero';
import { Link } from 'src/containers/Link';
import { TableContainer } from 'src/components/atoms/TableContainer';

/**
 * Default website layout
 */
const Layout = ({ children, pageContext, headerImage, headerActions, headerOptions }) => {
  const title = get(pageContext, 'frontmatter.title');
  const subtitle = headerOptions?.subtitle ?? get(pageContext, 'frontmatter.subtitle');
  const category = get(pageContext, 'frontmatter.category');
  const actionLabel = get(pageContext, 'frontmatter.actionLabel');
  const actionLink = get(pageContext, 'frontmatter.actionLink');
  const alternativeHeader = get(pageContext, 'frontmatter.alternativeHeader');
  const slopedHeader = get(pageContext, 'frontmatter.slopedHeader') || headerOptions?.style === 'sloped';
  const width = headerOptions?.width ?? 'large';
  const subtitleWidth = headerOptions?.subtitleWidth ?? 'small';
  return (
    <>
      <Hero
        title={title}
        subtitle={subtitle}
        category={category}
        actions={headerActions || (actionLink ? <Button to={actionLink}>{actionLabel}</Button> : null)}
        image={headerImage}
        width={width}
        centered
        alternative={alternativeHeader}
        sloped={slopedHeader}
        wavey={headerOptions?.style === 'wavey'}
        primary={headerOptions?.primary}
        subtitleWidth={subtitleWidth}
      />

      <MDXProvider
        components={{
          TableContainer,
          a: ({ href, ...props }) => <Link to={href} {...props} />,
        }}
      >
        {children}
      </MDXProvider>
    </>
  );
};

export default Layout;
