import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import FacebookImage from 'src/images/facebook-og.png';
const query = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        url
        title
        description
        author
      }
    }
  }
`;
export const SEO = ({ title, description, lang = 'en', meta = [], keywords = [], image }) => {
  const data = useStaticQuery(query);
  const metaDescription = description || data.site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      {...(!!title
        ? {
            title,
          }
        : null)}
      defaultTitle={data.site.siteMetadata.title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        !!metaDescription
          ? {
              name: 'description',
              content: metaDescription,
            }
          : {},
        !!title
          ? {
              property: 'og:title',
              content: title,
            }
          : {},
        !!metaDescription
          ? {
              property: 'og:description',
              content: metaDescription,
            }
          : {},
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${data.site.siteMetadata.url}${image || FacebookImage}`,
        },
        {
          property: 'og:site_name',
          content: data.site.siteMetadata.title,
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        !!title
          ? {
              property: 'twitter:title',
              content: title,
            }
          : {},
        !!metaDescription
          ? {
              property: 'twitter:description',
              content: metaDescription,
            }
          : {},
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : {},
        {
          property: 'twitter:image',
          content: `${data.site.siteMetadata.url}${image || FacebookImage}`,
        },
        ...meta,
      ]}
    />
  );
};
