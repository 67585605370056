import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ShowcaseCategories as Component } from 'src/components/organisms/ShowcaseCategories';
const query = graphql`
  query ShowcaseCategoriesData {
    allDatoCmsShowcase(sort: { order: ASC, fields: position }) {
      nodes {
        category {
          title
          slug
        }
        slug
        heading
      }
    }
  }
`;
export const ShowcaseCategories = ({ activeCategory, activeShowcase }) => {
  const {
    allDatoCmsShowcase: { nodes },
  } = useStaticQuery(query);
  return <Component data={nodes} activeCategory={activeCategory} activeShowcase={activeShowcase} />;
};
