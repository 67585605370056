import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
export const List = styled.ul`
  list-style: none;
  padding: 0;
`;
List.displayName = 'List';
const ListIcon = styled.span`
  position: absolute;
  left: 0.25rem;
`;
export const ListItem = styled(({ icon, children, ...props }) => (
  <li {...props}>
    {!!icon && <ListIcon>{React.isValidElement(icon) ? icon : <Icon icon={icon} />}</ListIcon>} {children}
  </li>
))`
  position: relative;
  padding-left: ${(props) => props?.left || '1.75rem'};;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
ListItem.displayName = 'ListItem';
