import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled, { css } from 'styled-components';

import { normalizeTo } from 'src/helpers/normalize-link';
import { useSiteMeta } from 'src/hooks/use-site-meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
/**
 * Generic Link component that handles both Gatsby internal links as well as external links
 *
 * @see {@link https://www.gatsbyjs.org/docs/gatsby-link/|Gatsby Link}
 */

const MarginedFontAwesomeIcon = styled(FontAwesomeIcon)`
margin-left: 0.25rem;
`

export const Link = ({ to: oldTo, allowExternalLinkIcon, ...props }) => {
  const { url: siteUrl } = useSiteMeta();
  const { to, href } = normalizeTo(oldTo, {
    siteUrl,
    extSubGlobs: ['/docs/*', '/blog/*'],
  });
  return href ? <a href={href} rel="noopener noreferrer" {...props} /> : <><GatsbyLink to={to} {...props} />{allowExternalLinkIcon && <MarginedFontAwesomeIcon icon={faExternalLink} size='sm'/>}</>;
};
