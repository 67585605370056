import styled, { css } from 'styled-components';
import { Link } from 'src/containers/Link';
import React from 'react';
const CategoryContainer = styled.details`
  margin-bottom: 1.75rem;
  font-size: 0.875rem;
  color: black;

  ${({ active, theme }) =>
    active &&
    css`
      summary {
        color: ${theme.primaryColor};
      }
    `}
`;
const CategoryTitle = styled.summary`
  font-weight: bold;

  list-style: none;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.5;
  }

  &::-webkit-details-marker {
    display: none;
  }
`;
const CategoryList = styled.ul`
  margin: 1rem 0 1rem 0.5rem;
  padding: 0;
  list-style: none;
`;
const CategoryListItem = styled.li`
  border-left-width: 0.25rem;
  border-left-style: solid;
  border-color: ${({ active, theme }) => (active ? theme.primaryColor : 'transparent')};
  padding-left: 0.5rem;
  margin-bottom: 1rem;

  a {
    display: block;
    color: ${({ active, theme }) => (active ? theme.primaryColor : 'inherit')};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;
const Shoutout = styled.div`
  background-color: #dddcf6;
  color: ${({ theme }) => theme.primaryColor};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 1rem;

  a {
    text-decoration: underline;
  }
`;
export const ShowcaseCategories = ({ data, activeCategory, activeShowcase }) => {
  const showcasesByCategories = data.reduce((_, showcase) => {
    return {
      ..._,
      [showcase.category.slug]: {
        title: showcase.category.title,
        showcases: [...(_[showcase.category.slug]?.showcases || []), showcase],
      },
    };
  }, {});
  return (
    <>
      <h3>
        <Link to="/showcase">Community Showcase</Link>
      </h3>
      {Object.entries(showcasesByCategories).map(([category, { title, showcases }]) => (
        <CategoryContainer
          key={category}
          open={category === activeCategory}
          active={category === activeCategory}
        >
          <CategoryTitle>{title}</CategoryTitle>
          <CategoryList>
            {showcases.map((showcase) => (
              <CategoryListItem key={showcase.slug} active={showcase.slug === activeShowcase}>
                <Link to={`/showcase/${showcase.slug}`}>{showcase.heading}</Link>
              </CategoryListItem>
            ))}
          </CategoryList>
        </CategoryContainer>
      ))}
      <Shoutout>
        Have you built an assistant with Rasa?{' '}
        <Link href="https://docs.google.com/forms/d/e/1FAIpQLSfMJIcaCmzrfTB6yBkcScI10KuQckqTW_V6cLyCTG9LmF9PsQ/viewform">
          Get featured!
        </Link>
      </Shoutout>
    </>
  );
};
