import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'src/containers/Link';
const Container = styled(({ to, onClick, ...props }) =>
  to || onClick ? <Link to={to} {...props} /> : <div {...props} />,
)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(227, 231, 239, 0.41);
  box-shadow: 0 2px 4px 0 rgba(47, 17, 92, 0.1);
  border-radius: 0.5rem;
  min-height: 3.75rem;
  text-decoration: none;
  line-height: 1.5;
  color: inherit;
  height: 100%;
  margin-bottom: 24px;

  ${({ to }) =>
    !!to &&
    css`
      transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.3s cubic-bezier(0.25, 1, 0.5, 1);
      backface-visibility: hidden; /* Helps to avoid text blur on transform */

      &:hover {
        text-decoration: none;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.15);
        transform: translateY(-0.25rem) translateZ(0);
        cursor: pointer;
      }
    `}

  ${({ small }) =>
    small &&
    css`
      --docspanel-padding: 0.75rem;
    `}
`;
const Header = styled.header`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: var(--docspanel-padding, 1.25rem 1.25rem 1.25rem);

  h4 {
    margin: 0;
    line-height: 1.429;
  }

  p {
    margin-bottom: 0.5rem;
  }
`;
const Title = styled.h4`
  font-size: 16px;
  color: ${({ isLink }) => (isLink ? '#242424' : '#282525')};
  text-align: left;
`;

const Subtitle = styled.div`
  text-align: left;
`;
const Content = styled.div`
  padding: var(--docspanel-padding, 1rem 1.5rem 1.5rem);
  border-top: 1px solid #eef1f7;
  height: 100%;
  a:not([class]) {
    color: inherit;
    text-decoration: underline;
  }

  a:not([class]):hover {
    text-decoration: none;
  }

  .highlight {
    color: #595c79;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    text-transform: uppercase;

    &:after {
      background-color: rgba(76, 65, 222, 0.08);
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
const IconContainer = styled.div`
  flex-shrink: 0;
  width: ${({ small }) => (small ? '2.5rem' : '2.9375rem')};
  margin-right: ${({ small }) => (small ? '0.5rem' : '0.75rem')};
  text-align: center;
`;
const FooterIcon = styled.span`
  position: absolute;
  right: 0.5rem;
  bottom: 0.25rem;
`;
export const DocsPanel = ({
  title,
  subtitle,
  small,
  children,
  icon,
  footerIcon,
  to,
  onClick,
  noMarginBottom,
}) => (
  <Container to={to} onClick={onClick} small={small} noMarginBottom={noMarginBottom}>
    {(title || subtitle) && (
      <Header>
        {!!icon && <IconContainer small={small}>{icon}</IconContainer>}
        <div>
          <Title isLink={!!to}>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
      </Header>
    )}
    {!!children && <Content>{children}</Content>}
    {!!footerIcon && <FooterIcon>{footerIcon}</FooterIcon>}
  </Container>
);
