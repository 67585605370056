import React, { useEffect } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';
import { loadScript } from 'src/helpers/loadScript';
import { Link } from 'src/app/link';
import { Button } from 'src/components/atoms/Button';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { FooterBanner } from 'src/containers/FooterBanner';
import { useSiteFooterNavLinks } from 'src/hooks/use-site-footer-nav';
import { useSiteMeta } from 'src/hooks/use-site-meta';
import { RasaLogoSquare } from 'src/navigation/logos';
import * as sys from 'src/system';
import { Box, Chain } from 'src/system2';

const { McolGrid, Grid } = sys;

const socialLinks = [
  { href: 'https://twitter.com/Rasa_HQ', icon: ['fab', 'x-twitter'], text: '' },
  { href: 'https://www.linkedin.com/company/rasa.', icon: ['fab', 'linkedin'], text: '' },
  {
    href: 'https://stackoverflow.com/questions/tagged/rasa-nlu+or+rasa+or+rasa-core',
    icon: ['fab', 'stack-overflow'],
    text: '',
  },
  { href: 'https://github.com/RasaHQ/', icon: ['fab', 'github'], text: '' },
  { href: 'https://www.youtube.com/channel/UCJ0V6493mLvqdiVwOKWBODQ', icon: ['fab', 'youtube'], text: '' },
  { href: 'https://angel.co/company/rasahq', icon: ['fab', 'angellist'], text: '' },
];

export function SiteFooter() {
  return (
    <footer className="site site-footer">
      <SiteFooterNav />
    </footer>
  );
}

const badgeCss = css`
  margin-left: 1em;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--brand-color);
  color: white;
  font-size: 87.5%;
`;

const outerCss = css`
  background-color: var(--footer-background-color);
  padding: 40px 0;
  font-size: 15px;
  line-height: 1.5;
  ul,
  li,
  p,
  h4 {
    margin-bottom: 0;
  }
  h4 {
    font-size: 16px;
  }
`;

const blockCss = css`
  display: block;
`;

const nlFormGridCss = css`
  --row-gap: 20px;
  min-height: 106px; //! taken from measuring the loaded hubspot form
  @media (min-width: 800px) {
    --columns: 2;
  }
`;

const nlFormTextCss = css`
  --stack-gap: 20px;
  > * {
    margin: 0;
  }
  > * + * {
    margin-top: 6px;
  }
`;

const gridCss = css`
  padding-top: 30px;
  padding-bottom: 30px;
  --multi-columns: 2;
  @media (min-width: 600px) {
    --multi-columns: 3;
  }
  @media (min-width: 900px) {
    --multi-columns: 4;
  }
  @media (min-width: 1200px) {
    --multi-columns: 5;
  }
`;

const footerLinkCss = css`
  color: var(--footer-text-color);
  &:hover,
  &:active,
  &:focus {
    // color: blue;
  }
`;

// const FooterLogo = styled(RasaLogoSquare)`
//   color: ${(theme) => theme.primaryColor};
// `;

function SiteFooterNav() {
  const navLinks = useSiteFooterNavLinks();
  const siteMeta = useSiteMeta();

  useEffect(() => {
    async function handleAsync() {
      try {
        await loadScript('https://platform.linkedin.com/in.js');
      } catch (err) {
        console.error('LinkedIn Script Error:', err);
      }
    }

    handleAsync();
  }, []);

  return (
    <div css={[outerCss]}>
      <div css={[sys.stop, sys.wrapLast, sys.frame]}>
        <Grid css={[nlFormGridCss]}>
          <div css={[sys.stack, nlFormTextCss]}>
            <h4 css={`
              color: var(--footer-header-color);
            `}>Join our Newsletter</h4>
            <p css={`
              color: var(--footer-text-color);
            `}>
              Stay updated with the latest content and news from Rasa around conversational AI!
            </p>
          </div>
          <div className="cell">
            <NewsletterForm />
          </div>
        </Grid>
        <div
          css={`
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          `}
        >
          <McolGrid className="mcol-grid" css={[gridCss]}>
            <Link href="/" css={[blockCss, footerLinkCss]}>
              <RasaLogoSquare w="100px" style={{ color: '#5a17ee' }} />
              <p
                css={`
                  margin-top: 10px;
                  font-size: 14px;
                `}
              >
                {siteMeta.tagline}
              </p>
            </Link>
            {navLinks.map((link) => {
              return (
                <div className="cell" key={link.text}>
                  <h4 css={`
                    color: var(--footer-header-color);
                  `}>{link.text}</h4>
                  <ul
                    role="list"
                    css={`
                      margin-top: 10px;
                      padding: 0;
                      list-style: none;
                      > * + * {
                        margin-top: 5px;
                      }
                    `}
                  >
                    {link.items.map((subitem, j) => {
                      return (
                        <li key={subitem.text}>
                          <Link href={subitem.href} css={[footerLinkCss]}>
                            {subitem.icon && <FaIcon icon={subitem.icon} style={{ marginRight: '1em' }} />}
                            {subitem.text}
                            {subitem.xtra && <span css={[badgeCss]}>{subitem.xtra}</span>}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            <div>
              <p
                css={`
                  font-size: 12px;
                  font-weight: 600;
                  text-transform: uppercase;
                  margin-bottom: 8px !important;
                  color: #6B7280;
                `}>Like what you see?</p>
              <h4 css={`
                font-size: 1.25rem !important;
                margin-bottom: 8px !important;
                color: var(--footer-header-color);
              `}>Let's work together</h4>
              <p css={`
                margin-bottom: 1.5rem !important;
                color: var(--footer-text-color);
              `}>Get answers and a customized quote for your projects</p>
              <Button
                to="/request-for-proposal"
              >
                Submit RFP
              </Button>
            </div>
          </McolGrid>
        </div>
        <aside>
          <FooterBanner />
        </aside>
        <Chain
          sx={{
            gap: 20,
            ai: 'center',
            mb: '0.5rem'
          }}
        >
          {socialLinks.map((link) => (
            <div className="cell" key={link.href}>
              <Link href={link.href}>
                <FaIcon icon={link.icon} />
              </Link>
            </div>
          ))}
          <Box
            sx={{
              ml: 'auto',
            }}
          >
            <p>
              © Rasa Technologies Inc - 2024 | <a href="/imprint/">Imprint</a> |{' '}
              <a href="/privacy-policy/">Privacy Policy</a> |{' '}<a href="/security-at-rasa/">Security</a>
            </p>
          </Box>
        </Chain>
        <script type="IN/FollowCompany" data-id="4836263" data-counter="right"></script>
      </div>
    </div>
  );
}
