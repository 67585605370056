/* LOGIC
  - return any non-web links as href
  - normalize same-site links to /...
  - normalize other site links to https://...
  - normalize all relative and absolute same-site links to trailing slashes, observing hashes and queries !!
  - evaluate external links by compariing URL to baseDir and subDirGlobs

  QUESTONS
  - ? what to do with falsey URL values ?

*/

export function pathGlobRE(str) {
  str = str
    .replace(/^\/?/, '/')
    .replace(/\/?$/, '/?')
    .replace(/\/?\*\/\?$/, '/.+');
  return new RegExp(`^${str}$`, 'i');
}

export function pathStartRE(str) {
  str = str.replace(/^\/?/, '/');
  return new RegExp(`^${str}`, 'i');
}

export function normalizeTo(to, { siteUrl, baseDir = '/', extSubGlobs = [] }) {
  // if to is falsy, return it
  if (!to) return { to };
  /*

  1. NORMALIZATION

  */
  // idiot check
  to = to.trim();
  // normalize double-slash URL base
  to = to.replace(/^\/\//, 'https://');
  // test if url has a protocol
  const hasProtocol = /^(\w*:)/.test(to);
  // test if url is web or not
  const isWeb = /^https?:/.test(to);
  // if it has a protocol and is non-web, return it as href
  if (hasProtocol && !isWeb) return { href: to };
  // strip empty anchors
  to = to.replace(/#$/, '');
  // if a siteUrl is given, normalize any full URLs to root-basis

  /** Handle rasa.com linking to rasa.community issue */
  function handleToReplace() {
    const siteHostEscaped = new URL(siteUrl).hostname.replace(/\./g, '\\.');
    to = to.replace(new RegExp(`https?://${siteHostEscaped}`), '');
  }

  if (siteUrl === 'https://rasa.com' && !to.includes('rasa.community')) {
    handleToReplace();
  }

  if (siteUrl === 'https://rasa.community' && to.includes('rasa.community')) {
    handleToReplace();
  }
  /*

  2. INTERNAL / EXTERNAL

  */
  // set up for external testing
  let isExtHost, isExtPath, isHashOnly;
  // test if this is an external host
  isExtHost = /^https?:/.test(to);
  isHashOnly = /^#/.test(to);

  if (!isExtHost && !isHashOnly) {
    // parse out the parts, conform and test the pathname
    let { hash, search, pathname } = new URL(to, 'https://dummy');
    if (pathname) pathname = pathname.replace(/\/?$/, '/');
    const extPathREs = extSubGlobs.map((subDir) => pathGlobRE(`${baseDir}/${subDir}`));
    const basePathRE = pathStartRE(baseDir);
    isExtPath =
      (/^\//.test(pathname) && !basePathRE.test(pathname)) || extPathREs.some((RE) => RE.test(pathname));
    to = (pathname || '') + (search || '') + (hash || '');
  }
  const isRouter = !isExtHost && !isExtPath && !isHashOnly;
  return !isRouter ? { href: to } : { to };
}
