import React from 'react';

/* old */
import styled from 'styled-components';
import { AnnouncementBanner } from 'src/containers/AnnouncementBanner';
/* new */
import { SiteNav } from 'src/navigation/SiteNav';

const Header = styled.header`
  --bg: var(--header-bg, white);
  --fg: var(--header-fg, currentColor);
  --spacer-bg: var(--header-visible) var(--header-bg);
  background-color: var(--bg);
  color: var(--fg);
  height: 60px;
  position: sticky;
  top: -10px;
  z-index: 4;
`;

const SpacerAbove = styled.div`
  height: 10px;
  width: 100%;
  background-color: var(--spacer-bg);
`;

const SpacerBelow = styled.div`
  height: 50px;
  width: 100%;
  background-color: var(--spacer-bg);
`;

export function SiteHeader() {
  return (
    <>
      {process.env.GATSBY_WEBSITE_TYPE !== 'opensource' && (
        <aside>
          <AnnouncementBanner />
        </aside>
      )}
      <Header className="site-header">
        <SpacerAbove />
        <SiteNav />
        <SpacerBelow />
      </Header>
    </>
  );
}
