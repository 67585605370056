import styled from 'styled-components';
export const TableContainer = styled.div`
  overflow: auto;
  margin-bottom: 1.75rem;
  border-radius: 0.75rem;
  border: 1px solid #e5e5e5;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);

  table {
    margin: 0;
  }

  thead tr:first-child {
    > th,
    > td {
      border-top: 0;
    }
  }

  tr:last-child {
    > th,
    > td {
      border-bottom: 0;
    }
  }

  td:first-child,
  th:first-child {
    border-left: 0;
  }

  td:last-child,
  th:last-child {
    border-right: 0;
  }
`;
TableContainer.displayName = 'TableContainer';
