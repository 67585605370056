import React, {
  Children,
  cloneElement,
  useState,
  useCallback,
  // useRef,
  // useEffect,
} from 'react';
// import { useId } from '@reach/auto-id';
import cn from 'clsx';
// import { createGlobalStyle } from 'styled-components';
import {
  // SwitchTransition,
  CSSTransition,
  // Transition,
} from 'react-transition-group';

export function Xn({ show, name = 'fade', duration = 200, handleMount, children }) {
  const [xnState, setXnState] = useState(show ? 'entered' : 'exited');
  const onEnter = useCallback((_, appearing) => setXnState(appearing ? 'appear' : 'enter'));
  const onEntering = useCallback((_, appearing) => setXnState(appearing ? 'appearing' : 'entering'));
  const onEntered = useCallback((_, appearing) => setXnState(appearing ? 'appeared' : 'entered'));
  const onExit = useCallback(() => setXnState('exit'));
  const onExiting = useCallback(() => setXnState('exiting'));
  const onExited = useCallback(() => setXnState('exited'));
  const child = Children.only(typeof children === 'function' ? children(xnState !== 'exited') : children);
  return (
    <CSSTransition
      in={show}
      timeout={duration}
      mountOnEnter={handleMount}
      unmountOnExit={handleMount}
      appear={true}
      {...{
        onEnter,
        onEntering,
        onEntered,
        onExit,
        onExiting,
        onExited,
      }}
    >
      {cloneElement(child, {
        className: cn(
          child.props.className,
          {
            appear: `xn-${name}-out xn-${name}-appear`,
            appearing: `xn-${name} xn-${name}-in xn-${name}-appear`,
            appeared: `xn-${name}-in xn-${name}-appear`,
            enter: `xn-${name}-out`,
            entering: `xn-${name} xn-${name}-in`,
            entered: `xn-${name}-in`,
            exit: `xn-${name}-in`,
            exiting: `xn-${name} xn-${name}-out`,
            exited: `xn-${name}-out`,
          }[xnState],
        ),
        style: { ...child.props.style, '--xn-duration': `${duration}ms` },
      })}
    </CSSTransition>
  );
}
