import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { SEO } from 'src/containers/SEO';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { InlineList } from 'src/components/molecules/InlineList';
import { Box } from 'src/components/atoms/Box';
import { Badge } from 'src/components/atoms/Badge';
import { Shuffler } from 'src/components/atoms/Shuffler';
import { Teaser } from 'src/components/molecules/Teaser';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { Video } from 'src/components/atoms/Video';
import { SummitContainer } from 'src/components/atoms/SummitContainer';
import * as React from 'react';
export default {
  Section,
  Grid,
  Column,
  SEO,
  Button,
  Card,
  InlineList,
  Box,
  Badge,
  Shuffler,
  Teaser,
  NewsletterForm,
  Video,
  SummitContainer,
  React
};