import React from 'react';
import { Helmet } from 'react-helmet';
import { css } from 'styled-components';
import { SkipNavLink as SkipLink, SkipNavContent as SkipLinkTarget } from '@reach/skip-nav';

import '@reach/skip-nav/styles.css';
import { SiteHeader } from 'src/layouts/SiteHeader';
import { SiteFooter } from 'src/layouts/SiteFooter';

const siteCss = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* align-items: start; */
  & > * {
    width: 100%;
    flex: 0 0 auto;
  }
  & > #main {
    flex: 1 1 auto;
  }
`;

const SiteLayout = ({
  /*
    relevant options
    - noindex the page? (set at page level)
    - hide the newsletter form in footer
   */

  pageContext = {},
  children,
}) => {
  return (
    <div id="site" css={siteCss}>
      {pageContext.noIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <SkipLink />
      <SiteHeader {...{ pageContext }} />
      <SkipLinkTarget />
      <main id="main" className="site-main">
        {children}
      </main>
      <SiteFooter {...{ pageContext }} />
    </div>
  );
};

export default SiteLayout;
