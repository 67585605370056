import random from 'lodash/random';
import shuffle from 'lodash/shuffle';
import React, { useEffect, useState } from 'react';
import { Section } from 'src/components/molecules/Section';
import { ProfileImage } from 'src/containers/ProfileImage';
import styled, { css, keyframes } from 'styled-components';
const Container = styled((props) => <Section {...props} />)`
  overflow: hidden;
  display: flex;
  min-height: 30rem;
  flex-direction: column;
  justify-content: center;
`;
const Side = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 28rem;
  width: 24vw;
  max-width: 20rem;
  z-index: 2;

  ${({ right }) =>
    right
      ? css`
          left: 105%;
        `
      : css`
          right: 105%;
        `}

  @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
    display: block;
  }
`;
const floatAnimation = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
`;
const CommunityMember = styled((props) => <ProfileImage medium alt="A community member" {...props} />)`
  position: absolute !important;
  margin: 0;
  animation: ${floatAnimation} 4s ease-in-out infinite;
  animation-direction: alternate;
  animation-delay: ${({ animationDelay = `${random(0, 3, true)}s` }) => animationDelay};

  ${({ top, right, bottom, left }) => css`
    display: ${!top && !right && !bottom && !left ? 'none' : 'block'} !important;
    top: ${top || 'auto'};
    right: ${right || 'auto'};
    bottom: ${bottom || 'auto'};
    left: ${left || 'auto'};
  `}
`;
export const CommunitySection = ({ profileImages, children, ...props }) => {
  const [shuffledCommunityMembers, setShuffledCommunityMembers] = useState(profileImages);
  useEffect(() => {
    setShuffledCommunityMembers((c) => shuffle(c));
  }, []);
  return (
    <Container centered maxWidth="small" {...props}>
      {!!shuffledCommunityMembers && (
        <Side>
          <CommunityMember data={shuffledCommunityMembers[0]} left="5px" top="0" />
          <CommunityMember data={shuffledCommunityMembers[1]} right="0" top="5px" />
          <CommunityMember data={shuffledCommunityMembers[2]} left="110px" top="100px" />
          <CommunityMember data={shuffledCommunityMembers[3]} right="-20px" top="150px" />
          <CommunityMember data={shuffledCommunityMembers[4]} left="0" top="220px" />
          <CommunityMember data={shuffledCommunityMembers[5]} left="180px" top="230px" />
          <CommunityMember data={shuffledCommunityMembers[6]} left="70px" top="340px" />
          <CommunityMember data={shuffledCommunityMembers[7]} right="0" top="320px" />
        </Side>
      )}

      {children}

      {!!shuffledCommunityMembers && (
        <Side right>
          <CommunityMember data={shuffledCommunityMembers[8]} left="40px" top="5px" />
          <CommunityMember data={shuffledCommunityMembers[9]} right="20px" top="0" />
          <CommunityMember data={shuffledCommunityMembers[10]} left="0" top="140px" />
          <CommunityMember data={shuffledCommunityMembers[11]} right="80px" top="120px" />
          <CommunityMember data={shuffledCommunityMembers[12]} left="100px" top="220px" />
          <CommunityMember data={shuffledCommunityMembers[13]} right="0" top="240px" />
          <CommunityMember data={shuffledCommunityMembers[14]} left="-20px" top="300px" />
          <CommunityMember data={shuffledCommunityMembers[15]} right="100px" top="340px" />
        </Side>
      )}
    </Container>
  );
};
