import React, { forwardRef } from 'react';
import { Link as GatsbyLink, withPrefix } from 'gatsby';
import clsx from 'clsx';

import { useNormalizedHref } from 'src/hooks';

export const Link = forwardRef(function Link({ href: argHref, className, children, ...props }, ref) {
  /* NOTE re pathPrefix, if used
    1. must supply `stripBasePath: true`
    2. must wrap `to` in `withPrefix` on `GatsbyLink`
  */
  const { to, href, isExt } = useNormalizedHref(argHref, {
    siteUrl: process.env.GATSBY_WEBSITE_TYPE === 'opensource' ? 'https://rasa.community' : 'https://rasa.com',
    extSubPaths: ['docs', 'summit', 'blog', 'resources'],
    /* 1 */
    // stripBasePath: true,
  });
  if (isExt) {
    props.rel = `${props.rel || ''} noopener`;
    props.target = '_blank';
  }
  return to ? (
    <GatsbyLink
      to={to} /* 2 */
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: clsx(className, { current: isCurrent, 'partly-current': isPartiallyCurrent }),
      })}
      {...{ ref, ...props }}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a {...{ href, ref, className, ...props }}>{children}</a>
  );
});
