import { graphql, useStaticQuery } from 'gatsby';

export function useBlogLast3() {
  return useStaticQuery(graphql`
    query useBlogLast3 {
      allDatoCmsPost(
        sort: { fields: meta___firstPublishedAt, order: DESC }
        filter: { meta: { status: { eq: "published" } } }
        limit: 3
      ) {
        nodes {
          title
          slug
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          meta {
            firstPublishedAt(formatString: "MMM D YYYY")
          }
          authors {
            name
            image {
              ...GatsbyDatoCmsImageCustom
            }
          }
          featureImage {
            ...GatsbyDatoCmsImageCustom
          }
        }
      }
    }
  `);
}
