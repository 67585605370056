import shuffle from 'lodash/shuffle';
import React from 'react';
/**
 * A simple component that shuffles its children every time it's rendered.
 */

export const Shuffler = React.memo(({ children }) => {
  const shuffledChildren = shuffle(React.Children.toArray(children));
  return <>{shuffledChildren}</>;
});
Shuffler.displayName = 'Shuffler';
