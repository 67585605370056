import styled from 'styled-components';
import SummitBG from 'src/images/summit_bg.svg';
export const SummitContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    background-image: url(${SummitBG});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
  }
`;
