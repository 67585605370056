import styled from 'styled-components';
export const GuideList = styled.ul`
  list-style: none;
  padding-left: 1.75rem;
`;
export const GuideListItem = styled.li`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -1.5rem;
    display: block;
    border: 3px solid rgba(76, 65, 222, 0.58);
    border-radius: 100%;
    background-color: white;
    width: 1rem;
    height: 1rem;
    z-index: 2;
  }

  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: -1.16rem;
    display: block;
    background-color: rgba(76, 65, 222, 0.08);
    width: 5px;
    height: 100%;
    z-index: 1;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
