import React from 'react';
import styled from 'styled-components';

import { SEO } from 'src/containers/SEO';
import { DocsFooter } from 'src/components/molecules/DocsFooter';
import { DocsHeader } from 'src/components/molecules/DocsHeader';
const Container = styled.div`
  font-size: 0.875rem;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  letter-spacing: 0.0429px;
`;
/**
 * Docs website layout
 */

const DocsLayout = ({ children }) => {
  return (
    <Container>
      <SEO
        title="Rasa: Developer Documentation Portal"
        description="Get started with Rasa Open Source conversational AI and Rasa X. Find documentation, videos, tutorials and resources to build chatbots and voice assistants."
      />
      <DocsHeader />
      {children}
      <DocsFooter />
    </Container>
  );
};

export default DocsLayout;
