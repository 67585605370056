import { createGlobalStyle } from 'styled-components';
import modernNormalize from 'styled-modern-normalize';

export const theme = {
  baseFontColor: '#6d708b',
  baseHeaderColor: '#343434',
  primaryColor: '#5a17ee',
  secondaryColor: '#4DD6D2',
  // Fonts
  smallFontSize: '0.875rem',
  largeFontSize: '1.125rem',

  // Section widths
  smallContentWidth: '720px',
  mediumContentWidth: '960px',
  largeContentWidth: '1070px',
  xlargeContentWidth: '1260px',

  // Breakpoints
  xsmallBreakpoint: '640px',
  smallBreakpoint: '800px',
  mediumBreakpoint: '1140px',
  largeBreakpoint: '1340px'
};

export const GlobalStyle = createGlobalStyle`
  ${modernNormalize}

  @font-face {
    font-family: 'Graphik_Web';
    src: url('https://assets.rasa.com/fonts/Graphik-Regular-Web-us-ascii.woff2') format('woff2'),
      url('https://assets.rasa.com/fonts/Graphik-Regular-Web-us-ascii.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    unicode-range: U+20-7E;
  }

  @font-face {
    font-family: 'Graphik_Web';
    src: url('https://assets.rasa.com/fonts/Graphik-Medium-Web-us-ascii.woff2') format('woff2'),
      url('https://assets.rasa.com/fonts/Graphik-Medium-Web-us-ascii.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    unicode-range: U+20-7E;
  }

  @font-face {
    font-family: 'Graphik_Web';
    src: url('https://assets.rasa.com/fonts/Graphik-Semibold-Web-us-ascii.woff2') format('woff2'),
      url('https://assets.rasa.com/fonts/Graphik-Semibold-Web-us-ascii.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    unicode-range: U+20-7E;
  }

  @font-face {
    font-family: 'Graphik_Web';
    src: url('https://assets.rasa.com/fonts/Graphik-Bold-Web.woff2') format('woff2'),
      url('https://assets.rasa.com/fonts/Graphik-Bold-Web.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MonoLisa_Web';
    src: url('https://assets.rasa.com/fonts/MonoLisa-Regular.woff2') format('woff2'),
      url("https://assets.rasa.com/fonts/MonoLisa-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MonoLisa_Web';
    src: url('https://assets.rasa.com/fonts/MonoLisa-Bold.woff2') format('woff2'),
      url("https://assets.rasa.com/fonts/MonoLisa-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  :root {
    --primary-color: ${({ theme }) => theme.primaryColor};
  }

  html {
    // font-smooth
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;

    font-family: sans-serif;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    font-kerning: normal;
    text-rendering: optimizeLegibility;

    hyphens: auto;
    quotes: auto;
    overflow-wrap: break-word;
    word-break: normal;
    word-wrap: break-word; // old version of overflow-wrap
  }

  body, .f-sans {
    font-family: 'Graphik_Web', Arial, sans-serif;
    font-feature-settings: "liga", "kern";
    font-size: 1.125rem;
    line-height: 1.556;
    color: ${({ theme }) => theme.baseFontColor};
  }

  .f-mono, h5, code, kbd, pre {
    font-family: 'MonoLisa_Web', monospace;
  }

  body.offcanvas-open {
    transform: translateX(-17rem);
    overflow: hidden;

    @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
      transform: none;
      overflow: initial;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1.75rem;
    color: var(--alternative-heading-color, ${({ theme }) => theme.baseHeaderColor});
    font-weight: bold;
    line-height: 1.2;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1em;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 0.7em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4a457c;
    margin-bottom: 1rem;
    font-weight:600;
  }

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    h1 {
      font-size: 3.125rem;
    }

    h2 {
      font-size: 2.25rem;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1em;
      margin-bottom: 1rem;
    }

    h5 {
      font-size: 0.7em;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4a457c;
      margin-bottom: 1rem;
      font-weight:600;
    }
  }

  p, ul, ol {
    margin: 0 0 1.75rem;
  }

  hr {
    border-top: 0;
    margin: 0 0 1.5rem;
    color:#e5e5e5;
  }

  a {
    color: var(--primary-color, ${({ theme }) => theme.primaryColor});
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    object-fit: contain;
  }

  img:not([height]) {
    height: auto;
  }

  label {
    line-height: 1.5;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    color: #343434;
    font-size: 0.875rem;
    text-align: left;
    margin: 0 0 1.5rem;

    th, td {
      border: solid 1px #e5e5e5;
      padding: .5rem 0.875rem;
    }

    thead th {
      text-align: center;
    }

    tbody th {
      font-weight: bold;
      background-color: #fafafa;
      text-align: left;
    }
  }

  .highlight {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      background-color: rgba(255, 246, 74, 0.74);
      left: -2%;
      right: -2%;
      bottom: -1px;
      height: 33.3%;
      z-index: -1;
    }
  }

  strong {
    font-weight: 500;
  }

  .react-phone-number-input {
    border: solid 1px #ced5e7;
    border-radius: 8px;
    padding: 0.25rem 0;
    line-height: 1.6;
    background-color: white;
  }

  .react-phone-number-input__row {
    display: flex;
  }

  .react-phone-number-input__country {
    position:relative;
    padding: 0.25rem .75rem;
    border-right: 1px solid #ced5e7;
    cursor: pointer;
  }

  .react-phone-number-input__country-select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .react-phone-number-input__phone {
    flex: 1;
    border: 0;
    width: 100%;
    padding: .25rem .75rem;
    appearance: none;
  }

  blockquote {
    margin: 0 0 1.75rem;
  }

  .embed-widget {
    overflow: hidden;
    border-radius: 5px;
    color: ${({ theme }) => theme.baseFontColor};
    --primary-color: ${({ theme }) => theme.primaryColor};
    font-size: 87.5%;
    background-color: white;

    .twitter-tweet {
      width: auto !important;
      min-width: 0 !important;
      max-width: 500px !important;
      margin: 0 auto !important;

      &:not(.twitter-tweet-rendered) {
        padding: 1.5rem;
      }
    }

    iframe {
      display: block;
      width: 100%;
    }
  }

  code,
  kbd,
  pre {
    font-size: 1em;
  }

  code,
  pre {
  }

  code {
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
  }

  pre {
    margin: 0 0 1.75rem;
  }

  pre > code {
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    padding: 0;
    white-space: pre;
    word-break: normal;
  }
`;
