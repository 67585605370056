import styled, { css } from 'styled-components';
import React from 'react';
export const Badge = styled(({ spacing, margin, secondary, ...props }) => <span {...props} />)`
  display: inline-block;
  padding: 0.1rem 0.5rem;
  ${({ spacing }) =>
    spacing &&
    css`
      margin: 0 0.5rem;
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: 1.25rem;
    `}
  border-radius: 0.25rem;
  background-color: ${({ theme, secondary }) => (secondary ? theme.secondaryColor : theme.primaryColor)};
  color: white;
  font-size: 87.5%;
`;
Badge.displayName = 'Badge';
