module.exports = {
  defaultModKey: 'm',

  // set a breakpoint every 12em
  breakpoints: {
    xs: '24em', // 384px -> large phones
    s: '36em', // 576px -> small tablets
    m: '48em', // 768px -> ipads and up
    ml: '60em', // 960px -> small desktop
    l: '72em', // 1152px -> medium desktop
    xl: '84em', // 1344px -> large desktop
    xxl: '96em', // 1536px -> xlarge desktop
  },

  fontSizes: {
    sans: {
      xs: { _: 12, s: 13, ml: 14, xl: 16 },
      s: { _: 14, s: 15, ml: 16, xl: 18 },
      m: { _: 16, s: 17, ml: 18, xl: 20 },
      l: { _: 20, s: 26, ml: 28, xl: 30 },
      xl: { _: 24, s: 32, ml: 36, xl: 40 },
    },
    mono: {
      xs: { _: 12, s: 13, ml: 14, xl: 16 },
      s: { _: 14, s: 15, ml: 16, xl: 18 },
      m: { _: 16, s: 17, ml: 18, xl: 20 },
      l: { _: 20, s: 26, ml: 28, xl: 30 },
    },
  },

  spacings: {
    outerX: { _: '1rem', xs: '1.5rem', s: '2rem', m: '3rem' },
    outerY: { _: '1.5rem', l: '3rem' },
    innerX: { _: '2rem', m: '3rem' },
    innerY: {
      xs: '0.5rem',
      s: '1rem',
      m: '2rem',
      l: '3rem',
    },
  },

  // SCHEMES/COLORS
  colors: {
    brand: '#5a17ee',
  },

  schemes: require('./tabula.palettte.json').reduce((schemes, { paletteName, swatches }) => {
    const [schemeName, colorName] = paletteName.trim().split('-');
    schemes[schemeName] = schemes[schemeName] || {};
    schemes[schemeName][colorName] = swatches.map(({ color }) => `#${color}`);
    return schemes;
  }, {}),
};
