import React from 'react';
import styled from 'styled-components';

import { HubspotForm } from 'src/components/atoms/HubspotForm';

const CustomForm = styled(HubspotForm)`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0;
  color: var(--footer-text-color);

  form {
    position: relative;
    display: grid;
    margin: 0 0 1.75rem;
    max-width: 100%;

    @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
      grid-template-columns: 1fr max-content;
    }
  }

  .hs-form-field {
    position: relative;
    margin: 0;

    > label {
      display: none;
    }

    .hs-field-desc {
      display: none;
    }

    .hs-error-msgs {
      position: absolute;
      top: 100%;
      width: 100%;
      font-size: 0.75rem;
    }
  }

  .hs-input {
    outline: none;

    &:not([type='checkbox']) {
      border-radius: 0;
    }
  }

  .hs_recaptcha {
    position: absolute;

    .grecaptcha-badge {
      display: none;
    }
  }

  // Don't need to display all errors
  .hs_error_rollup {
    display: none;
  }

  .legal-consent-container {
    grid-row: 3;
    margin-top: 1rem;
    font-size: 0.75rem;
  }

  .hs-richtext > p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    .hs-email,
    .hs-submit {
      grid-row: 1;
    }

    .legal-consent-container {
      grid-row: 2;
      grid-column: 1 / span 2;
    }
  }

  .hs-email .hs-input {
    border-radius: 8px 8px 0 0;
    height: 42px;
    line-height: unset;

    @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
      border-radius: 8px 0 0 8px;
    }
  }

  .hs-submit {
    z-index: 1;

    input[type='submit'] {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0;
      padding: 0.4375rem 1rem;
      border-radius: 0 0 8px 8px;
      outline: none;

      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;
export const NewsletterForm = () => (
  <CustomForm
    formId={
      process.env.GATSBY_WEBSITE_TYPE === 'opensource'
        ? 'ecc2d7e8-cbe6-4a71-ad24-1caf7068a9d2'
        : 'b4463a11-e6e8-4605-a529-56aa7bca3bd4'
    }
  />
);
