import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { Button } from 'src/components/atoms/Button';
import { Banner } from 'src/components/molecules/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const query = graphql`
  query FooterBarData {
    datoCmsFooterBar {
      id
      text
      display
      ctaText
      ctaUrl
    }
  }
`;

const COOKIE_EXPIRY_DAYS = 60;
const COOKIE_NAME = 'hasSeenFooterBanner';

const TrademarkBannerClose = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 1.25em;
  width: 2em;
  height: 2em;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  cursor: pointer;
`;

export const FooterBanner = () => {
  const data = useStaticQuery(query);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasSeenBanner = Boolean(Cookies.get(COOKIE_NAME));
    if (!hasSeenBanner) {
      setShowBanner(true);
    }
  }, []);

  if (!data.datoCmsFooterBar.display || !showBanner) {
    return null;
  }

  return (
    <Banner css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'var(--footer-background-color)'
    }}>
      <div>
        <span css={`
          color: var(--footer-text-color);
        `}>{data.datoCmsFooterBar.text}</span>
        {data.datoCmsFooterBar.ctaText
          && data.datoCmsFooterBar.ctaUrl
          && (
            <span css={{ marginLeft: '1em' }}>
              <Button
                css={`
                  color: var(--footer-text-color);
                `}
                to={data.datoCmsFooterBar.ctaUrl}
                target="_blank"
                secondary
                tiny>
                {data.datoCmsFooterBar.ctaText}
              </Button>
            </span>
        )}
      </div>
      <div>
        <TrademarkBannerClose
          css={`
            color: var(--footer-text-color);
          `}
          onClick={() => {
            setShowBanner(false);
            Cookies.set(COOKIE_NAME, 'true', {
              expires: COOKIE_EXPIRY_DAYS,
            });
          }}>
          <FontAwesomeIcon icon={['far', 'times']} />
        </TrademarkBannerClose>
      </div>
    </Banner>
  );
};
