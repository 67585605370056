import React, { Children } from 'react';
import styled, { css } from 'styled-components';

const StopDiv = styled.div`
  padding: 0.1px;
`;

const FlowDiv = styled.div(
  ({ gap }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${gap * -1}px;
    & > * {
      flex: 0 0 auto;
      max-width: 100%;
      padding: ${gap}px;
    }
  `,
);

export function FlowAlt({ children, gap = 10 }) {
  return (
    <StopDiv>
      <FlowDiv gap={gap}>
        {Children.map(children, (child, i) => (
          <div key={i}>{child}</div>
        ))}
      </FlowDiv>
    </StopDiv>
  );
}
