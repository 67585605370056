import React from 'react';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
export const BottomCTA = () => (
  <Section dark centered spacious>
    <h2>Start creating your own contextual AI assistant!</h2>

    <InlineList centered>
      <li>
        <Button to="/docs/">Get Started</Button>
      </li>
      <li>
        <Button to="https://info.rasa.com/rasa-technical-demo" secondary>
          Schedule a technical demo
        </Button>
      </li>
    </InlineList>
  </Section>
);
