import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
/** Removed gatsby-airtable */
// const query = graphql`
//   query CommunityChaptersQuery {
//     allAirtable(filter: { queryName: { eq: "communityChapters" } }) {
//       edges {
//         node {
//           data {
//             flag: Flag
//             name: Name
//             link: Meetup_Link
//           }
//         }
//       }
//     }
//   }
// `;
export const CommunityChapters = () => {
  /** Removed gatsby-airtable */
  // const data = useStaticQuery(query);
  let data = [];
  const chapters = data.allAirtable.edges.map(({ node: { data } }) => data);

  if (!chapters.length) {
    return null;
  }

  return (
    <InlineList centered>
      {chapters.map(({ flag, name, link }) => (
        <li key={link}>
          <Button to={link}>
            {flag} Rasa Community {name} Chapter
          </Button>
        </li>
      ))}
    </InlineList>
  );
};
