import { graphql, useStaticQuery } from 'gatsby';

export function useSiteMeta() {
  const data = useStaticQuery(graphql`
    {
      site {
        ...SiteMeta
      }
    }
  `);

  return data.site.siteMetadata;
}
