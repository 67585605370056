/*
  routerHref
  anchorHref
  isExternal
 */
export const normalizePathSegment = (segment) => segment.replace(/^\/?(.+?)?\/?$/, '/$1');

export function conformSlash(url, trailingSlash) {
  const match = url.match(/(#|\?)(.+)?/);
  const parts = match ? [url.slice(0, match.index), url.slice(match.index)] : [url];
  const part0 = trailingSlash
    ? parts[0].length > 0
      ? `${parts[0]}/`.replace(/\/\/$/, '/') // add slash and remove double-slashes
      : parts[0]
    : parts[0].replace(/\/$/, ''); // remove any trailing slash
  const part1 = parts[1] && parts[1].length > 1 ? parts[1] : '';
  return part0 + part1;
}

export function normalizeHref(
  href,
  {
    siteUrl,
    basePath = '/',
    extSubPaths = [],
    stripBasePath = false,
    trailingSlash = true,
    lowerCase = true,
  },
) {
  /**
   *
   * PART I: early returns
   *
   */
  // if href is falsy, return it
  if (!href) return href;
  // normalize double-slash URL base
  href = href.replace(/^\/\//, 'https://');
  // test if url has a protocol
  const hasProtocol = /^(\w*:)/.test(href);
  // test if url is web, i.e. http(s)
  const isWeb = /^https?:/.test(href);
  // if to has a protocol and is non-http(s), return it
  if (hasProtocol && !isWeb) return { href };
  /**
   *
   * PART II: parse internal vs external links
   *
   */
  // normalize basePath
  basePath = normalizePathSegment(basePath);
  // conform trailing slash
  href = conformSlash(href, trailingSlash);
  // parse out hostName
  const hostName = siteUrl ? new URL(basePath, siteUrl).hostname : false;
  // parse out hostName
  if (hostName) href = href.replace(new RegExp(`^https?://${hostName}`), '');
  // determine if href includes basePath
  const basePathRE = new RegExp(`^${basePath}`);
  const hadBasePath = href.match(basePathRE) !== null;
  // strip the basePath if asked
  if (stripBasePath) href = href.replace(basePathRE, '');
  // determine if href still contains http(s), therefore external
  const isExtHost = /^https?:/.test(href);
  // determine if the href links an "external sub-directory"
  const isExtPath = extSubPaths.some((subPath) => {
    subPath = normalizePathSegment(subPath);
    return new RegExp(`^${subPath}`).test(href);
  });
  return !isExtHost && !isExtPath
    ? { to: lowerCase ? href.toLowerCase() : href }
    : {
        href: isExtHost
          ? hostName
            ? href.replace(new RegExp(`^https?://${hostName}`), '')
            : href
          : stripBasePath && hadBasePath
          ? `${basePath}${href}`.replace(/\/\//g, '/')
          : href,
        isExt: isExtHost,
      };
}
