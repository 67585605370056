import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'src/containers/Link';
import MaskImage from 'src/images/teaser_mask.svg';
import { HighlightedText } from 'src/components/atoms/HighlightedText';
const Container = styled(({ to, layout, forceLayout, ...props }) =>
  to ? <Link to={to} {...props} /> : <div {...props} />,
)`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-height: 100%;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: white;
  font-size: 1rem;

  --alternative-heading-color: initial;

  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-0.25rem);
  }

  ${({ to }) =>
    to &&
    css`
      cursor: pointer;
    `}

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (min-width: ${({ theme, forceLayout }) =>
      forceLayout ? theme.xsmallBreakpoint : theme.smallBreakpoint}) {
    ${({ layout }) =>
      layout === 'horizontal' &&
      css`
        flex-direction: row;
      `}
  }
`;
const TeaserHeader = styled.header`
  ${({ clipped }) => clipped && `mask: url(${MaskImage}) bottom center;`}
  mask-size: 100% auto;
  border-radius: 1rem 1rem 0 0;

  ${({ fit }) =>
    fit === 'centered' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    `}

  @media (min-width: ${({ theme, forceLayout }) =>
    forceLayout ? theme.xsmallBreakpoint : theme.smallBreakpoint}) {
    ${({ layout }) =>
      layout === 'horizontal' &&
      css`
        width: 33%;
      `}
  }
`;
const TeaserTitle = styled.h3`
  margin-bottom: 0.5rem;
`;
const TeaserContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const TeaserMainContent = styled.div`
  padding: 1.25rem;
  flex: 1;
  color: #6d708b;

  > *:last-child {
    margin-bottom: 0;
  }
`;
const TeaserSubheader = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;
const TeaserFooter = styled.div`
  padding: 0 1.25rem 1.25rem;
  font-weight: bold;
`;
export const Teaser = ({
  to,
  header,
  subtitle,
  title,
  footer,
  children,
  clipHeader,
  layout = 'vertical',
  headerFit = 'full',
  forceLayout,
  ...props
}) => (
  <Container layout={layout} forceLayout={forceLayout} to={to} {...props}>
    {!!header && (
      <TeaserHeader fit={headerFit} layout={layout} forceLayout={forceLayout} clipped={clipHeader}>
        {header}
      </TeaserHeader>
    )}
    <TeaserContent>
      <TeaserMainContent>
        {!!subtitle && (
          <TeaserSubheader>
            <HighlightedText>{subtitle}</HighlightedText>
          </TeaserSubheader>
        )}
        {!!title && <TeaserTitle>{title}</TeaserTitle>}
        {children}
      </TeaserMainContent>
      {!!footer && <TeaserFooter>{footer}</TeaserFooter>}
    </TeaserContent>
  </Container>
);
